/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Grid
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-grid {
  --grid-x-spacing: #{rem(24px)};
  --grid-y-spacing: #{rem(24px)};

  display: flow-root;
}

  .o-grid__items {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--grid-y-spacing) * -0.5);
    margin-right: calc(var(--grid-x-spacing) * -0.5);
    margin-bottom: calc(var(--grid-y-spacing) * -0.5);
    margin-left: calc(var(--grid-x-spacing) * -0.5);
  }

    .o-grid__item {
      width: 100%;
      padding-top: calc(var(--grid-y-spacing) * 0.5);
      padding-right: calc(var(--grid-x-spacing) * 0.5);
      padding-bottom: calc(var(--grid-y-spacing) * 0.5);
      padding-left: calc(var(--grid-x-spacing) * 0.5);
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-grid--form {
  --grid-x-spacing: #{rem(10px)};
  --grid-y-spacing: #{rem(20px)};
}

.o-grid--news {
  .o-grid__item:nth-child(3n - 1) {
    @include mq($from: "medium", $until: "large") {
      margin-top: rem(30px);
    }

    @include mq($from: "large") {
      margin-top: rem(60px);
    }
  }
}

.o-grid--catalog {
  @include mq($until: "medium") {
    --grid-x-spacing: #{rem(12px)};
    --grid-y-spacing: #{rem(30px)};
  }

  @include mq($from: "medium") {
    --grid-y-spacing: #{rem(40px)};
  }
}
