/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Card
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-card {
  position: relative;
  color: var(--card-color, currentColor);

  @media only print {
    break-inside: avoid;
  }
}

.c-card--ls-small {
  @include mq($until: "medium") {
    display: flex;
    align-items: center;
    border: rem(1px) solid color("white");
  }
}

.c-card--interactive {
  @include transition(background box-shadow color);

  &:hover,
  &:focus-within {
    --card-color: #{color("black")};

    background: color("white");
    box-shadow: rem(0 10px 10px) rgba(color("black"), 0.05);
  }
}

.c-card--light {
  --card-color: #{color("foreground")};
}

.c-card--dark {
  --card-color: #{color("white")};
}

  .c-card__head {
    position: relative;

    .c-card--ls-small & {
        @include mq($until: "medium") {
          flex: 0 0 auto;
          width: 32%;
          margin-top: 0;
          margin-right: 0;
        }
      }
  }

    .c-card__picture {
      overflow: hidden;

      img {
        @include transition(transform);
      }

      .c-card--interactive:hover &,
      .c-card--interactive:focus-within & {
        img {
          transform: scale(1.05);
        }
      }

      .c-card--ls-small & {
        @include mq($until: "medium") {
          transform: translateX(#{rem(-1px)});
        }
      }
    }

    .c-card__category {
      @include typo-headline($at: "small");

      padding: rem(7px 12px);
      color: color("white");
      background: color("black");

      // In head
      .c-card__head & {
        position: absolute;
        bottom: 0;
        left: rem(20px);
      }

      .c-card--ls-small .c-card__head & {
        @include mq($until: "medium") {
          display: none;
        }
      }

      // In body
      .c-card__body & {
        @include mq($until: "medium") {
          display: inline-block;
          margin-top: rem(-10px);
          margin-bottom: rem(5px);
          vertical-align: top;
        }
      }

      .c-card:not(.c-card--ls-small) .c-card__body & {
        display: none;
      }

      .c-card--ls-small .c-card__body & {
        @include mq($from: "medium") {
          display: none;
        }
      }
    }

  .c-card__body {
    padding: rem(10px 20px 20px);
    border: rem(1px) solid color("white");
    border-top: 0;
    @include transition("background");

    .c-card--ls-small & {
      @include mq($until: "medium") {
        flex: 1 1 auto;
        padding: rem(10px);
        margin-right: 0;
        border: 0;
      }
    }
  }

    .c-card__date {
      display: block;
      margin-bottom: rem(5px);
      color: color("secondary");
    }

    .c-card__title {
      margin: 0;
      font-family: $font-serif;
      line-height: math.div(24.68, 18);

      @include mq($until: "medium") {
        font-size: rem(16px);
      }

      @include mq($from: "medium") {
        font-size: rem(18px);
      }
    }

      .c-card__link {
        text-decoration: none;

        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }

    .c-card__button {
      position: relative;
      margin-top: rem(10px);
      color: var(--card-color); // Prevent link's color transition to take longer time than exepected.

      .c-card--ls-small & {
        @include mq($until: "medium") {
          display: none;
        }
      }
    }
