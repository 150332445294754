/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form {}

  .c-form__feedback {
    margin-bottom: rem(20px);
  }

  .c-form__fields {}

  .c-form__actions {
    margin-top: rem(30px);
    text-align: right;
  }

  .c-form:not(.c-form--display-required) .c-form__required-indic {
    @include hidden-visually;
  }
