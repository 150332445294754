/**********************************************************************************
 * TEXT - UTILITIES
 **********************************************************************************/


/**
 * Styles
 **********************************************************************************/

.u-typo-bordered {
  display: inline-flex;
  align-items: center;
  text-align: left;

  &::after {
    content: "";
    flex: 0 0 auto;
    height: rem(1px);
    background: currentColor;
  }

  @include mq($until: "medium") {
    &::after {
      width: rem(32px);
      margin-left: rem(10px);
    }
  }

  @include mq($from: "medium") {
    &::after {
      margin-left: rem(20px);
    }
  }

  @include mq($from: "medium", $until: "large") {
    &::after {
      width: rem(40px);
    }
  }

  @include mq($from: "large") {
    &::after {
      width: rem(74px);
    }
  }
}
