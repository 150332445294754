/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Option
 * Type: Component
 * Description: A single option respresented as a checkbox or radio button.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$option-size: rem(18px);
$option-gap: rem(8px);
$option-checked-color: color("black");
$option-checked-contrast-color: color("background");
$option-radio-checked-offset: rem(2px);
$option-top-alignment: rem(-2px);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-option {
  display: inline-flex;
}

  .c-form-option__control {
    position: relative;
    z-index: 1;
    flex: 0 0 auto;
  }

    .c-form-option__input,
    .c-form-option__doppel {
      display: block;
      width: $option-size;
      height: $option-size;
    }

    .c-form-option__input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
    }

    .c-form-option__doppel {
      position: relative;
      border: rem(2px) solid color("foreground");
      @include transition(background box-shadow color);

      .c-form-option__input:hover ~ &,
      .c-form-option__input.focus-visible ~ & {
        background: rgba($option-checked-color, 0.1);
      }

      .c-form-option--light & {
        border-width: rem(1px);
      }
    }

      .c-form-option__icon {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: $option-checked-contrast-color;
        @include transition(transform);

        .c-form-option__input:not(:checked) ~ .c-form-option__doppel & {
          transform: scale(0);
        }
      }

  .c-form-option__details {
    flex: 1 1 auto;
    margin-left: $option-gap;
    @include typo-small;
  }

    .c-form-option__label {
      display: block;
      margin-top: $option-top-alignment;
      font-weight: $FONT_REGULAR;

      a {
        font-weight: $FONT_BOLD;
      }
    }

/**
 * Checkbox
 ******************************************************************************/

.c-form-option--checkbox {
  .c-form-option__doppel {
    border-radius: rem(2px);
  }

  &.c-form-option--light .c-form-option__doppel {
    border-radius: rem(5px);
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    color: $option-checked-contrast-color;
    background: $option-checked-color;
    border-color: $option-checked-color;
  }
}

/**
 * Radio
 ******************************************************************************/

.c-form-option--radio {
  .c-form-option__doppel {
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      top: $option-radio-checked-offset;
      right: $option-radio-checked-offset;
      bottom: $option-radio-checked-offset;
      left: $option-radio-checked-offset;
      background: $option-checked-color;
      border-radius: 50%;
      transform: scale(0);
      @include transition(transform);
    }
  }

  .c-form-option__input:checked ~ .c-form-option__doppel {
    border-color: $option-checked-color;

    &::before {
      transform: scale(1);
    }
  }
}

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-option--error {
  .c-form-option__doppel {
    background: color("error-100");
    border-color: transparent;
  }

  .c-form-option__input:not(:checked) ~ .c-form-option__doppel {
    box-shadow: rem(0 0 0 2px) color("error");
  }
}
