/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Filters
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

$animation-duration: 500ms;

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes filters-backdrop-opening {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes filters-backdrop-closing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes filters-pane-opening {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes filters-pane-closing {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-filters {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $global-z-overlay;

  &[aria-hidden="true"] {
    display: none;
  }

  @include mq($until: "medium") {
    --filters-pane-y-gutter: #{rem(30px)};
    --filters-pane-x-gutter: var(--wrapper-gutter);
    --filters-pane-max-width: #{rem(400px)};
    --filters-rubrics-spacing: #{rem(10px)};
  }

  @include mq($from: "medium") {
    --filters-pane-y-gutter: #{rem(50px)};
    --filters-pane-x-gutter: #{rem(50px)};
    --filters-pane-max-width: #{rem(690px)};
    --filters-rubrics-spacing: #{rem(30px)};
  }

  @media only print {
    display: none;
  }
}

  .c-filters__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(color("black"), 0.3);

    .c-filters.is-opening & {
      animation: filters-backdrop-opening $animation-duration;
    }

    .c-filters.is-closing & {
      animation: filters-backdrop-closing $animation-duration;
    }
  }

  .c-filters__pane {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: var(--filters-pane-max-width);
    height: 100%;
    padding: var(--filters-pane-y-gutter) var(--filters-pane-x-gutter);
    margin-left: auto;
    overflow: auto;
    overscroll-behavior: contain;
    background: color("white");

    .c-filters.is-opening & {
      animation: filters-pane-opening $animation-duration;
    }

    .c-filters.is-closing & {
      animation: filters-pane-closing $animation-duration;
    }
  }

    .c-filters__pane-content {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

      .c-filters__close {
        @include typo-small;

        flex: 0 0 auto;
        align-self: flex-end;
        padding: 0;
        font-weight: $FONT_BOLD;
        background: transparent;
        border: 0;

        .c-icon {
          margin-right: rem(8px);
          font-size: rem(16px);
        }
      }

      .c-filters__rubrics {
        width: 100%;
        padding-top: var(--filters-rubrics-spacing);
        padding-bottom: var(--filters-rubrics-spacing);
      }

        .c-filters__rubric {
          @include mq($from: "medium") {
            & + & {
              margin-top: rem(20px);
            }
          }
        }

          .c-filters__rubric-summary {
            position: relative;
            display: block;
            padding-right: rem(40px);
            cursor: pointer;

            &::-webkit-details-marker {
              display: none;
            }

            &::marker {
              font-size: 0;
            }

            &::before {
              content: "" !important; // Override default IE/Edge declaration
            }

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: 0;
              width: rem(24px);
              height: rem(24px);
              margin-top: rem(-12px);
              background: icon(map.get($global-icons, "chevron-down"), color("foreground")) no-repeat center;
              background-size: 100%;
              @include transition(transform);

              details[open] &,
              summary[aria-expanded="true"] & {
                transform: rotate(180deg);
              }
            }
          }

            .c-filters__rubric-title {
              display: block;
              padding-top: rem(10px);
              padding-bottom: rem(10px);
              margin-top: 0;
              margin-bottom: 0;
              font-weight: $FONT_EXTRABOLD;
              text-transform: uppercase;
              letter-spacing: em(1px, 14px);

              span {
                font-weight: $FONT_REGULAR;
                text-transform: none;
              }

              &[data-selected]:not([data-selected="0"])::after {
                content: " (" attr(data-selected) ")";
              }

              @include mq($until: "medium") {
                font-size: rem(14px);
              }

              @include mq($from: "medium") {
                font-size: rem(16px);
              }
            }

          .c-filters__rubric-content {
            padding-bottom: rem(10px);
            margin-top: rem(10px);
          }

            .c-filters__options {
              display: flex;
              flex-wrap: wrap;
              margin: rem(-5px);
            }

              .c-filters__option {
                padding: rem(5px);
              }

    .c-filters__actions-container {
      position: sticky;
      bottom: 0;
      z-index: 1;
      display: flex;
      flex: 0 0 auto;
      justify-content: flex-end;
      background: color("white");

      &::before {
        content: "";
        position: absolute;
        top: -20px;
        right: 0;
        left: 0;
        height: 20px;
        background: color("white");
      }

      // Mask underlying elements
      &::after {
        content: "";
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        height: var(--filters-pane-y-gutter);
        background: color("white");
      }
    }

      .c-filters__actions {
        --filters-actions-spacing: #{rem(10px * 0.5)};

        display: flex;
        flex-wrap: wrap;
        max-width: max-content;
        margin: calc(var(--filters-actions-spacing) * -1);

        @include mq($until: "medium") {
          --filters-actions-spacing: #{rem(10px * 0.5)};
        }

        @include mq($from: "medium") {
          --filters-actions-spacing: #{rem(20px * 0.5)};
        }
      }

        .c-filters__action {
          flex: 1 1 auto;
          margin: var(--filters-actions-spacing);
        }
