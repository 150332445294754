/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Product's head
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@include mq-add-breakpoint("product-head-cols", 1180px);
@include mq-add-breakpoint("product-head-ultrawide", 1920px);

$product-head-large-infos-spacing: rem(80px);
$product-head-large-infos-spacing: 4%;
$product-head-large-infos-max-width: rem(385px);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-product-head {
  --product-head-infos-spacing: var(--wrapper-gutter);

  @include mq($from: "product-head-cols") {
    display: flex;
  }

  @include mq($from: "product-head-cols", $until: "product-head-ultrawide") {
    --product-head-infos-spacing: 4%;
  }

  @include mq($from: "product-head-ultrawide") {
    --product-head-infos-spacing: #{rem(80px)};
  }
}

  .c-product-head__gallery-container {
    @include mq($from: "product-head-cols") {
      flex: 1 1 auto;
      max-width: calc(100% - (#{$product-head-large-infos-max-width} + 2 * var(--product-head-infos-spacing)));
    }
  }

    .c-product-head__gallery {
      position: sticky;
      top: global-spacing();
      @include transition(top);

      .header-is-displayed & {
        top: calc(var(--header-height) + #{global-spacing()});
      }
    }

  .c-product-head__infos {
    display: flex;
    flex-direction: column;
    padding-right: var(--product-head-infos-spacing);
    padding-left: var(--product-head-infos-spacing);

    > * {
      margin-top: 0;
      margin-bottom: 0;
    }

    > * + * {
      margin-top: global-spacing();
    }

    @include mq($until: "product-head-cols") {
      max-width: calc(#{rem(700px)} + 2 * var(--product-head-infos-spacing));
      margin-top: global-spacing();
      margin-right: auto;
      margin-left: auto;
    }

    @include mq($from: "product-head-cols") {
      flex: 0 0 auto;
      width: 100%;
      width: calc(#{$product-head-large-infos-max-width} + 2 * var(--product-head-infos-spacing));
    }
  }

    .c-product-head__title {
      @include typo-h2;
    }

    .c-product-head__price {
      @include typo-headline;
    }

    .c-product-head__actions-container {}

      .c-product-head__actions {
        display: flex;
        flex-wrap: wrap;
        margin: global-spacing(-0.5);
      }

        .c-product-head__action {
          padding: global-spacing(0.5);
        }

        .c-product-head__action--full {
          width: 100%;
        }

    .c-product-head__doclist {
      padding: global-spacing();
      border: rem(1px) solid color("primary-background");
      border-radius: rem(10px);
    }

        .c-product-head__doclist-title {
          margin: 0;
          font-family: $font-sans-serif;
          font-size: rem(12px);
          font-weight: $FONT_REGULAR;
          text-transform: uppercase;
          letter-spacing: em(2px, 12px);
        }

        .c-product-head__doclist-items {
          padding: 0;
          margin: rem(10px) 0 0;
          list-style: none;

          @include mq($from: "medium", $until: "product-head-cols") {
            columns: 2;
            column-gap: rem(24px);
          }
        }

          .c-product-head__doclist-item {
            font-weight: $FONT_EXTRABOLD;
            text-transform: uppercase;
            letter-spacing: 0.04em;

            & + & {
              margin-top: rem(10px);
            }
          }

            .c-product-head__doclist-link {
              display: flex;
              text-decoration: none;

              .c-icon {
                flex: 0 0 auto;
                font-size: rem(24px);
                @include transition(color);
              }

              &:hover,
              &.focus-visible {
                .c-icon {
                  color: color("secondary");
                }
              }
            }

              .c-product-head__doclist-label {
                flex: 1 1 auto;
              }
