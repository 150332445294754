/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Chessboard
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-chessboard {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: rem(600px);
    background: color("primary-background");
  }
}

  .c-chessboard__wrapper {
    position: relative;
  }

    .c-chessboard__head {
      padding-bottom: rem(30px);
      text-align: center;

      @include mq($until: "medium") {
        padding-top: rem(30px);
      }

      @include mq($from: "medium") {
        padding-top: rem(60px);
      }
    }

      .c-chessboard__suptitle {}

      .c-chessboard__title {
        @include typo-h2;

        margin: rem(20px 0 0);
      }

    .c-chessboard__items {}

      .c-chessboard__item {
        position: relative;
        color: var(--chessboard-item-color, inherit);
        background: var(--chessboard-item-bg, inherit);

        &:nth-child(3n - 2) {
          --chessboard-item-color: #{color("white")};
          --chessboard-item-bg: #{color("black")};
        }

        &:nth-child(3n - 1) {
          --chessboard-item-color: #{color("black")};
          --chessboard-item-bg: #{color("primary")};
        }

        &:nth-child(3n) {
          --chessboard-item-color: #{color("black")};
          --chessboard-item-bg: #{color("white")};
        }

        @include mq($from: "medium") {
          display: flex;

          &:nth-child(2n) {
            flex-direction: row-reverse;
          }
        }
      }

        .c-chessboard__item-picture {
          position: relative;
          flex: 1 1 auto;
          overflow: hidden;

          img {
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @include transition(transform);

            .c-chessboard__item:hover &,
            .c-chessboard__item:focus-within & {
              transform: scale(1.05);
            }
          }

          @include mq($until: "medium") {
            height: rem(145px);
          }
        }

        .c-chessboard__item-body {
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          justify-content: center;

          @include mq($until: "medium") {
            padding: rem(30px 50px);
          }

          @include mq($from: "medium") {
            width: 48%;
            max-width: rem(486px);
            padding: rem(50px 36px);
          }

          @include mq($from: "medium", $until: "large") {
            min-height: rem(220px);
          }

          @include mq($from: "large") {
            min-height: rem(320px);
          }
        }

          .c-chessboard__item-body-inner {
            width: 100%;
            max-width: rem(280px);
            margin: auto;
          }

            .c-chessboard__item-title {
              @include typo-h3;

              margin: 0;
            }

            .c-chessboard__item-content {
              margin-top: rem(10px);
            }

            .c-chessboard__item-button {
              margin-top: rem(20px);

              &::before {
                content: "";
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
              }
            }
