/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Loc card
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-loc-card {
  --loc-card-accent-color: #{color("black")};

  padding-bottom: rem(20px);
  padding-left: rem(20px);
  background: color("white");
  box-shadow: rem(0 20px 40px) rgba(0, 0, 0, 0.25);

  > * + * {
    margin-top: rem(10px);
  }

  @media only print {
    break-inside: avoid;
  }
}

.c-loc-card--reseller {
  --loc-card-accent-color: #{color("secondary")};
}

  .c-loc-card__type {
    border-bottom: rem(1px) solid var(--loc-card-accent-color);
  }

    .c-loc-card__type-inner {
      display: inline-block;
      padding: rem(4px 10px);
      font-size: rem(12px);
      font-weight: $FONT_BLACK;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      color: color("white");
      background: var(--loc-card-accent-color);
    }

  .c-loc-card__data {
    display: flex;
    align-items: baseline;
    padding-right: rem(20px);
  }

    .c-loc-card__icon {
      flex: 0 0 auto;
      margin-right: rem(10px);
      font-size: rem(16px);
      color: color("secondary");
    }

    .c-loc-card__value {
      font-weight: $FONT_BOLD;
    }

      .c-loc-card__title {
        @include typo-h5;
      }
