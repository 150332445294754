/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Search
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-search {}

  .c-search__form {
    position: relative;
    z-index: 1;
    background: color("white");
    box-shadow: rem(0 20px 40px) rgba(0, 0, 0, 0.25);

    @include mq($until: "medium") {
      padding: rem(20px) var(--wrapper-gutter);
      margin-right: calc(var(--wrapper-gutter) * -1);
      margin-left: calc(var(--wrapper-gutter) * -1);
    }

    @include mq($from: "medium") {
      max-width: rem(990px);
      padding: rem(40px);
      margin-right: auto;
      margin-left: auto;
    }
  }

    .c-search__form-inner {
      max-width: rem(590px);
      margin-right: auto;
      margin-left: auto;
    }

  .c-search__count {
    text-align: center;

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(50px);
    }
  }

  .c-search__results {
    max-width: rem(790px);
    margin-right: auto;
    margin-left: auto;

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(50px);
    }
  }

    .c-search__rubric {
      text-align: center;
    }

    .c-search__rubric + .c-search__rubric {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        width: 100%;
        max-width: rem(590px);
        height: rem(1px);
        background: color("secondary");
        transform: translateX(-50%);
      }

      @include mq($until: "medium") {
        padding-top: rem(20px);
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        padding-top: rem(40px);
        margin-top: rem(40px);
      }

      @media only print {
        border-top: 1px solid;
      }
    }

      .c-search__rubric-title {}

      .c-search__rubric-items,
      .c-search__rubric-more {
        @include mq($until: "medium") {
          margin-top: rem(20px);
        }

        @include mq($from: "medium") {
          margin-top: rem(40px);
        }
      }

      .c-search__rubric-items {
        padding: 0;
        margin-bottom: 0;
        text-align: left;
        list-style: none;
      }

        .c-search__result {
          position: relative;

          @include mq($from: "medium") {
            display: flex;
          }
        }

        .c-search__result + .c-search__result {
          @include mq($until: "medium") {
            margin-top: rem(20px);
          }

          @include mq($from: "medium") {
            margin-top: rem(40px);
          }
        }

          .c-search__result-picture {
            @include mq($until: "medium") {
              max-width: rem(160px);
              margin-bottom: rem(10px);
            }

            @include mq($from: "medium") {
              flex: 0 0 auto;
              max-width: rem(280px);
              margin-right: rem(24px);
            }
          }

          .c-search__result-infos {}

            .c-search__result-title {
              @include typo-h4;

              margin-top: 0;
              margin-bottom: 0;
              color: color("secondary");
            }

              .c-search__result-link {
                text-decoration: none;

                &::before {
                  content: "";
                  position: absolute;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                }

                &:hover,
                &.focus-visible {
                  text-decoration: underline;
                }
              }

            .c-search__result-excerpt {
              margin-top: rem(10px);
              margin-bottom: 0;
            }

  .c-search__foot {
    margin-top: rem(30px);
    text-align: center;
  }
