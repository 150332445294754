/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Dark cards section
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-darkcards-section {
  padding-top: var(--darkcards-section-pad);
  padding-bottom: var(--darkcards-section-pad);
  color: color("white");
  background: color("black");

  @include mq($until: "medium") {
    --darkcards-section-pad: #{rem(30px)};
  }

  @include mq($from: "medium") {
    --darkcards-section-pad: #{rem(60px)};
  }
}

  .c-darkcards-section__wrapper {}

    .c-darkcards-section__title {
      @include typo-h2;

      margin-top: 0;
      margin-bottom: var(--darkcards-section-title-margin);
      text-align: center;

      @include mq($until: "medium") {
        --darkcards-section-title-margin: #{rem(20px)};
      }

      @include mq($from: "medium") {
        --darkcards-section-title-margin: #{rem(40px)};
      }
    }

    .c-darkcards-section__content {}
