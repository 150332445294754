/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Breadcrumb
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$breadcrumb-prev-char: "<";
$breadcrumb-sep-char: ">";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-breadcrumb {
  font-size: rem(12px);
  font-weight: $FONT_LIGHT;
  letter-spacing: em(1px, 12px);
}

  .c-breadcrumb__items {
    padding: 0;
    margin: 0;
    list-style: none;
  }

    .c-breadcrumb__item {
      display: inline;

      @include mq($until: "large") {
        display: none;

        &:nth-last-child(2) {
          display: inline-block;
          font-weight: $FONT_BOLD;

          &::before {
            content: $breadcrumb-prev-char;
          }
        }
      }

      @include mq($from: "large") {
        & + & {
          &::before {
            content: $breadcrumb-sep-char;
            margin-right: 1ch;
            margin-left: 1ch;
          }
        }
      }
    }

    .c-breadcrumb__item--current {
      font-weight: $FONT_BOLD;
    }

      .c-breadcrumb__link {
        text-decoration: none;
      }

        .c-breadcrumb__label {}
