/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Big Button
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-big-button {
  position: relative;
  z-index: 1;
  padding: rem(20px 34px);
  color: var(--big-button-color);
  background: var(--big-button-bg);
}

  .c-big-button__inner {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin: rem(-10px);
  }

    .c-big-button__icon,
    .c-big-button__text,
    .c-big-button__button {
      margin: rem(10px);
    }

    .c-big-button__icon {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: rem(50px + 10px);
      height: rem(50px + 10px);
      font-size: rem(40px);
      color: var(--big-button-icon-color);
      border-radius: 50%;
      box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
        background: var(--big-button-icon-bg);
        border-radius: 50%;
      }

      &::before {
        background: color("white");
      }

      &::after {
        box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);
        transform: scale(0.8);
      }
    }

    .c-big-button__text {
      @include typo-h5;

      flex: 1 1 auto;
      width: calc(100% - 100px);
      max-width: max-content;
    }

    .c-big-button__custom a {
      display: block;
    }

    .c-big-button__button,
    .c-big-button__custom a {
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .c-big-button__green-number {
      svg {
        display: block;
        width: 100%;
        max-width: rem(265px);
      }
    }

/*******************************************************************************
 * Variants
*******************************************************************************/

.c-big-button--primary {
  --big-button-bg: #{color("cream")};
  --big-button-color: currentColor;
  --big-button-icon-bg: #{color("black")};
  --big-button-icon-color: #{color("white")};
}

.c-big-button--secondary {
  --big-button-bg: #{color("black")};
  --big-button-color: #{color("white")};
  --big-button-icon-bg: #{color("white")};
  --big-button-icon-color: #{color("black")};
}
