/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Newsletter form
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-newsletter {
  border: rem(1px) solid;

  @include mq($until: "medium") {
    padding: rem(20px 28px);
  }

  @include mq($from: "medium") {
    display: flex;
    flex-wrap: wrap;
    padding: rem(36px 40px);
  }

  .c-form__feedback {
    width: 100%;
  }
}

  .c-newsletter__aside {
    @include mq($from: "medium") {
      width: 46%;
      margin-right: rem(60px);
    }
  }

    .c-newsletter__title {
      @include typo-h2;

      margin-top: 0;
      margin-bottom: rem(10px);
    }

  .c-newsletter__main {
    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      flex: 1 1 auto;
    }
  }

    .c-newsletter__submit {
      margin-top: rem(20px);
    }
