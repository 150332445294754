/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --footer-large-bp: #{mq-px2em(mq-get-breakpoint-width("large"))};
}

.c-footer {
  @media only print {
    display: none;
  }
}

  .c-footer__main {
    --footer-main-spacing: #{rem(20px)};

    padding-top: var(--footer-main-spacing);
    padding-bottom: var(--footer-main-spacing);
    color: color("white");
    background: color("black");

    @include mq($from: "large") {
      --footer-main-spacing: #{rem(60px)};
    }
  }

    .c-footer__sitemap {}

    .c-footer__socials {
      margin-top: var(--footer-main-spacing);
    }
