/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Catalog push
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-catalog-push {}

  .c-catalog-push__head-wrapper {}

    .c-catalog-push__head {
      max-width: rem(800px);
      margin-right: auto;
      margin-left: auto;
      text-align: center;

      @include mq($until: "large") {
        padding-bottom: rem(30px);
      }

      @include mq($from: "large") {
        padding-bottom: rem(50px);
      }
    }

      .c-catalog-push__suptitle {}

      .c-catalog-push__title {
        @include typo-h2;

        margin: rem(20px 0 0);
      }

  .c-catalog-push__body-wrapper {
    @include mq($until: "large") {
      padding-top: rem(20px);
      padding-bottom: rem(20px);
      overflow: hidden;
      background: color("black");
    }

    @include mq($from: "large") {
      padding-top: rem(150px);
    }

    @include mq($from: "xlarge") {
      --wrapper-gutter: #{rem(140px)};
    }
  }

    .c-catalog-push__body {
      color: color("white");

      @include mq($from: "large") {
        display: flex;
        padding-top: rem(80px);
        padding-right: var(--wrapper-gutter);
        padding-bottom: rem(50px);
        padding-left: var(--wrapper-gutter);
        margin-right: calc(var(--wrapper-gutter) * -1);
        margin-left: calc(var(--wrapper-gutter) * -1);
        background: color("black");
      }
    }

      .c-catalog-push__intro {
        @include mq($from: "large") {
          flex: 0 0 auto;
          width: 23%;
          min-width: min-content;
          max-width: rem(385px);
          margin-right: rem(40px);
        }
      }

        .c-catalog-push__intro-title {
          @include typo-h2;

          margin-top: 0;
          margin-bottom: 0;
          color: color("primary");
        }

        .c-catalog-push__intro-caption {
          margin-top: rem(10px);
          margin-bottom: 0;
        }

        .c-catalog-push__intro-button {
          @include mq($until: "large") {
            margin-top: rem(10px);
          }

          @include mq($from: "large") {
            margin-top: rem(20px);
          }
        }

    .c-catalog-push__slider {
      @include mq($until: "large") {
        margin-top: rem(30px);
      }

      @include mq($from: "large") {
        flex: 1 1 auto;
        min-width: 0;
        margin-top: rem(-155px - 80px);
      }

      .swiper {
        padding-right: var(--wrapper-gutter);
        margin-right: calc(var(--wrapper-gutter) * -1);

        @include mq($until: "large") {
          padding-left: var(--wrapper-gutter);
          margin-left: calc(var(--wrapper-gutter) * -1);
        }
      }

      .swiper-wrapper {
        @media only print {
          display: grid;
          grid-template-columns: repeat(auto-fill, 160px);
          grid-gap: rem(20px);
        }
      }

      .swiper-slide {
        @include mq($until: "large") {
          max-width: rem(160px);
        }

        @include mq($from: "large") {
          max-width: rem(380px);
        }

        @media only print {
          padding: 0 !important;
          margin: 0 !important;
          border: 0 !important;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        @include mq($until: "large") {
          display: none;
        }

        @include mq($from: "large") {
          position: absolute;
          top: rem(225px); // 50% of picture
          z-index: 1;
          transform: translateY(-50%);
        }
      }

      .swiper-button-prev {
        @include mq($from: "large") {
          left: rem(40px);
        }
      }

      .swiper-button-next {
        @include mq($from: "large") {
          right: rem(40px);
        }
      }

      .swiper-scrollbar {
        --scrollbar-color: #{color("neutral")};

        @include mq($from: "large") {
          display: none;
        }
      }
    }

      .c-catalog-push__card {
        position: relative;

        > * {
          margin-top: 0;
          margin-bottom: 0;
        }

        > * + * {
          margin-top: rem(5px);
        }

        @media only print {
          break-inside: avoid;
        }
      }

        .c-catalog-push__card-picture {
          overflow: hidden;

          img {
            @include transition(transform);

            .c-catalog-push__card:hover &,
            .c-catalog-push__card:focus-within & {
              transform: scale(1.05);
            }
          }
        }

        .c-catalog-push__card-title,
        .c-catalog-push__card-desc {
          padding-right: rem(10px);
        }

        .c-catalog-push__card-title {
          @include typo-h4;

          @include mq($from: "large") {
            margin-top: rem(10px);
          }
        }

          .c-catalog-push__card-link {
            text-decoration: none;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
            }
          }

        .c-catalog-push__card-desc {}

        .c-catalog-push__card-icon {
          @include transition(transform);

          .c-catalog-push__card:hover &,
          .c-catalog-push__card:focus-within & {
            transform: translateX(#{rem(10px)});
          }

          @include mq($until: "large") {
            font-size: rem(20px);
          }

          @include mq($from: "large") {
            font-size: rem(34px);
          }
        }
