/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Icon list
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-iconlist {}

  .c-iconlist__title {
    margin-bottom: rem(10px);
    font-family: $font-sans-serif;
    font-size: rem($global-font-size);
    font-weight: $FONT_EXTRABOLD;
    text-transform: uppercase;
    letter-spacing: em(1px, $global-font-size);
  }

  .c-iconlist__items {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: rem(-12px);
    text-align: center;
    list-style: none;
  }

    .c-iconlist__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: rem(102px);
      padding: rem(12px);
      font-weight: $FONT_BOLD;
      line-height: (20 / 14);
    }

      .c-iconlist__icon {
        position: relative;
        padding: rem(12.5px);
        margin-bottom: rem(10px);
        font-size: rem(55px);

        .c-iconlist--bordered & {
          &::before {
            content: "";
            position: absolute;
            top: rem(12.5px);
            right: rem(12.5px);
            bottom: rem(12.5px);
            left: rem(12.5px);
            border: rem(1px) solid;
            border-radius: 50%;
          }
        }

        .c-iconlist--floating & {
          &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);
          }

          &::after {
            content: "";
            position: absolute;
            top: rem(12.5px);
            right: rem(12.5px);
            bottom: rem(12.5px);
            left: rem(12.5px);
            border-radius: 50%;
            box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);
          }
        }
      }
