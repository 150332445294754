/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Patriot
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-patriot {
  --patriot-spacing-y: #{rem(10px)};

  padding-top: var(--patriot-spacing-y);
  padding-bottom: var(--patriot-spacing-y);
  text-align: center;

  @include mq($from: "medium") {
    --patriot-spacing-y: #{rem(20px)};
  }
}

  .c-patriot__wrapper {}

    .c-patriot__link {
      text-decoration: none;

      @include mq($from: "medium") {
        display: inline-flex;
        align-items: center;
        justify-content: center;
      }
    }

      .c-patriot__label {
        position: relative;

        @include mq($until: "medium") {
          margin-bottom: rem(10px);
        }

        @include mq($from: "medium") {
          margin-right: calc(var(--label-dash-width) + #{rem(20px)});
        }
      }

        .c-patriot__flag {
          display: inline-block;
          width: rem(24px);
          margin-right: rem(10px);

          &::after {
            content: "";
            position: absolute;
            top: calc(#{rem(-1px)} + var(--patriot-spacing-y) * -1);
            bottom: var(--label-spacing-y);
            left: var(--label-spacing-x);
            width: rem(24px);
            background: linear-gradient(to right, #41479b 0%, #41479b calc(100% / 3), #fff calc(100% / 3), #fff calc(2 * 100% / 3), #ff4b55 calc(2 * 100% / 3));
          }
        }

      .c-patriot__text {
        @include typo-headline;
      }
