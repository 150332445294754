/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Figure
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-figure {
  text-align: center;
}

  .c-figure__media {
    overflow: hidden;

    .c-figure--image & {
      max-width: max-content;
      margin-right: auto;
      margin-left: auto;
    }
  }

  .c-figure__legend {
    @include typo-small;

    margin-top: rem(10px);
    text-align: left;
  }
