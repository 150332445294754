/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Contact
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-contact {}

  .c-contact__form-container {
    @include mq($until: "medium") {
      --wrapper-width: #{rem(360px)};
    }

    @include mq($from: "medium") {
      --wrapper-width: #{rem(690px)};
    }
  }

  .c-contact__blocks {
    @include mq($until: "medium") {
      margin-top: global-spacing(1.5);
    }

    @include mq($from: "medium", $until: "large") {
      margin-top: global-spacing(3);
    }

    @include mq($from: "large") {
      margin-top: global-spacing(5);
    }
  }

/**
 * Services Sections
*******************************************************************************/

.c-contact__highlight {
  --highlight-advertising-small-width: 100%;
}

  .c-contact__service {
    > * {
      margin: 0;
    }

    > * + * {
      margin-top: rem(15px);
    }

    & + & {
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        margin-top: rem(30px);
      }
    }
  }

    .c-contact__iw {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 90%;
      max-width: rem(255px);
      padding: rem(16px) rem(20px) rem(20px);
      color: color("foreground");
      background: color("white");
      border-top: rem(4px) solid color("secondary");
      filter: drop-shadow(#{rem(0 6px 20px)} rgba(0, 0, 0, 0.15));
      transform: translate(-50%, calc(-100% - #{rem(10px)}));

      @include mq($until: "medium") {
        padding: rem(6px) rem(10px) rem(10px);
      }

      @include mq($from: "medium") {
        padding: rem(16px) rem(20px) rem(20px);
      }

      &::before {
        content: "";
        position: absolute;
        bottom: rem(-10px);
        left: 50%;
        width: rem(10px);
        height: rem(10px);
        background: color("background");
        transform: translate(-50%, #{rem(-3px)}) rotate(45deg);
      }

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

      .c-contact__iw-title {
        @include typo-h5;
      }

      .c-contact__iw-address {}

      .c-contact__iw-link {
        font-weight: $FONT_BOLD;
      }

    .c-contact__service-title {
      @include typo-h5;

      color: color("secondary");
    }

    .c-contact__service-link {
      display: inline-flex;
      align-items: center;
      font-weight: $FONT_BOLD;

      .c-icon {
        margin-right: 10px;
        font-size: rem(24px);
      }
    }

    .c-contact__service-text {}
