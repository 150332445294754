/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Modal
 * Type: Component
 * Description: A dialog infowindow instanciate with the help of A11Y-dialog,
 *  see : https://a11y-dialog.netlify.app/
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$modal-close-size: rem(24px);
$modal-animation-duration: 500ms;

/*******************************************************************************
 * Base styles (A11Y dialog)
*******************************************************************************/

/**
 * 1. Make the dialog container, and its child overlay spread across
 *    the entire window.
 */
.c-modal,
.c-modal__backdrop {
  position: fixed; /* 1 */
  top: 0; /* 1 */
  right: 0; /* 1 */
  bottom: 0; /* 1 */
  left: 0; /* 1 */
  z-index: $global-z-overlay;
}

/**
 * 1. Make the dialog container a flex container to easily center the
 *    dialog.
 */
.c-modal {
  display: flex; /* 1 */
}

/**
 * 1. Make sure the dialog container and all its descendants are not
 *    visible and not focusable when it is hidden.
 */
.c-modal[aria-hidden='true'] {
  display: none; /* 1 */
}

/**
 * 1. Make the overlay look like an overlay.
 */
.c-modal__backdrop {
  background-color: rgba(color("black"), 0.3); /* 1 */
}

/**
 * 1. Vertically and horizontally center the dialog in the page.
 * 2. Make sure the dialog sits on top of the overlay.
 * 3. Make sure the dialog has an opaque background.
 */
.c-modal__dialog {
  position: relative; /* 2 */
  z-index: $global-z-overlay + 1; /* 2 */
  margin: auto; /* 1 */
  // background-color: $color-white; /* 3 */
}

/*******************************************************************************
 * Animations
*******************************************************************************/

@keyframes modal-backdrop-opening {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes modal-backdrop-closing {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes modal-dialog-opening {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modal-dialog-closing {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0);
  }
}

/*******************************************************************************
 * App styles
*******************************************************************************/

.c-modal {
  --modal-padding: var(--wrapper-gutter);
  --modal-dialog-padding: #{rem(20px)};

  padding: var(--modal-padding);
  overflow: auto;
}

  .c-modal__backdrop,
  .c-modal__dialog {
    animation-duration: $modal-animation-duration;
  }

  .c-modal__backdrop {
    .c-modal.is-opening & {
      animation-name: modal-backdrop-opening;
    }

    .c-modal.is-closing & {
      animation-name: modal-backdrop-closing;
    }
  }

  .c-modal__dialog {
    max-width: var(--modal-dialog-max-width, 100%);
    padding: var(--modal-dialog-padding);
    overflow: auto;
    background: color("white");

    .c-modal.is-opening & {
      animation-name: modal-dialog-opening;
    }

    .c-modal.is-closing & {
      animation-name: modal-dialog-closing;
    }
  }

    .c-modal__close {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $modal-close-size;
      height: $modal-close-size;
      padding: 0;
      font-size: rem(24px);
      color: color("black");
      background: transparent;
      border: 0;
    }
