/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Alert
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:color";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-alert {
  --alert-accent-color: #b28b30;
  --alert-background-color: #fbf4dd;

  display: flex;
  align-items: center;
  padding: rem(8px 10px);
  text-align: left;
  background: var(--alert-background-color);
  border-left: rem(4px) solid var(--alert-accent-color);
  border-radius: rem(0 6px 6px 0);
}

  .c-alert__icon {
    margin-right: rem(10px);
    font-size: rem(24px);
    color: var(--alert-accent-color);
  }

  .c-alert__content {}

    .c-alert__title {
      margin-top: 0;
      margin-bottom: 0;
      font-family: $font-serif;
      font-weight: $FONT_REGULAR;
      color: var(--alert-accent-color);
    }

    .c-alert__desc {
      font-size: rem(12px);
    }

.c-alert--error {
  --alert-accent-color: #{color("error")};
  --alert-background-color: #{color.mix(color("error"), #fff, 12%)};
}

.c-alert--success {
  --alert-accent-color: #{color("success")};
  --alert-background-color: #{color.mix(color("success"), #fff, 12%)};
}
