/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Label
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-label {
  --label-bg: #{color("background")};
  --label-color: #{color("foreground")};
  --label-border-color: #{color("foreground")};
  --label-spacing-y: #{rem(5px)};
  --label-spacing-x: #{rem(10px)};
  --label-dash-width: #{20px};

  position: relative;
  display: inline-block;
  padding: var(--label-spacing-y) calc(var(--label-spacing-x) + 0.5 * var(--label-dash-width)) var(--label-spacing-y) var(--label-spacing-x);
  font-family: $font-default;
  font-weight: $FONT_BOLD;
  color: var(--label-color);
  background: var(--label-bg);
  border: rem(1px) solid var(--label-border-color);

  @include mq($until: "medium") {
    font-size: rem(14px);
  }

  @include mq($from: "medium") {
    --label-spacing-y: #{rem(10px)};
    --label-spacing-x: #{rem(20px)};
    --label-dash-width: #{40px};

    font-size: rem(16px);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: var(--label-dash-width);
    height: rem(1px);
    margin-top: rem(-0.5px);
    margin-right: calc(var(--label-dash-width) * -0.5);
    background: color("black");
  }
}

.c-label--primary {
  --label-bg: #{color("primary")};
  --label-border-color: var(--label-bg);
}

.c-label--light {
  --label-border-color: var(--label-bg);
}
