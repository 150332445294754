/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Cookies
 * Type: Utility
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 *  Styles
*******************************************************************************/

.u-cookies-holder {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

.u-cookies-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: global-spacing(0.5);
  width: 100%;
  height: 100%;
  padding: global-spacing();
  line-height: 1.5;
  text-align: center;
  background: color("neutral");

  > * {
    margin: 0;
  }
}
