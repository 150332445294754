/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Media
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-media {}

.c-media--start {}

.c-media--end {}

.c-media--column {
  @include mq($from: "medium") {
    display: flex;

    &.c-media--end {
      flex-flow: row-reverse;
    }
  }
}

.c-media--float {
  display: flow-root;
}

  .c-media__figure {
    @include mq($from: "medium") {
      flex: 0 0 auto;
      width: 48%;

      &[class*="--image"] {
        max-width: max-content;
      }

      .c-media--start & {
        margin-right: rem(24px);
      }

      .c-media--end & {
        margin-left: rem(24px);
      }

      .c-media--float & {
        margin-bottom: rem(10px);
      }

      .c-media--start.c-media--float & {
        float: left;
      }

      .c-media--end.c-media--float & {
        float: right;
      }
    }
  }

  .c-media__text-container {
    .c-media--column & {
      display: flex;
      flex: 1 1 auto;
      flex-direction: column;
      justify-content: center;
    }
  }

    .c-media__text {
      @include mq($until: "medium") {
        margin-top: global-spacing();
      }
    }
