/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Product
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Structure styles
*******************************************************************************/

.c-index {}

  .c-index__subtitle {
    @include typo-h2;

    margin-top: 0;

    @include mq($until: "medium") {
      margin-bottom: rem(20px);
      text-align: center;
    }

    @include mq($from: "medium") {
      margin-bottom: rem(30px);
    }
  }

  .c-index__filters {
    margin-top: calc(var(--filter-bar-safe-t) * -1);
  }

  .c-index__filters--sticky-on-mobile {
    @include mq($until: "medium") {
      position: sticky;
      top: global-spacing();
      z-index: 1;
      @include transition(top);

      .header-is-displayed & {
        top: calc(var(--header-height) + #{global-spacing()});
      }
    }
  }

  .c-index__items,
  .c-index__noresult {
    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(30px);
    }
  }

  .c-index__pagination {
    text-align: center;

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium", $until: "large") {
      margin-top: rem(40px);
    }

    @include mq($from: "large") {
      margin-top: rem(60px);
    }
  }

    .c-index__pagination-indic {
      font-size: rem(16px);

      strong {
        font-weight: $FONT_BLACK;
      }
    }

    .c-index__pagination-button {
      margin-top: rem(20px);
    }

  .c-index__noresult {
    font-weight: $FONT_BOLD;
    text-align: center;
  }

  .c-index__related {
    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium", $until: "large") {
      margin-top: rem(40px);
    }

    @include mq($from: "large") {
      margin-top: rem(100px);
    }
  }

  .c-index__desc {
    max-width: rem(895px);

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium", $until: "large") {
      margin-top: rem(40px);
    }

    @include mq($from: "large") {
      margin-top: rem(60px);
    }
  }
