/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Timeline
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-timeline {
  --timeline-item-dot-outer-size: #{rem(30px)};
  --timeline-item-dot-inner-size: #{rem(20px)};
  --timeline-item-dot-top-offset: #{rem(7px)};

  position: relative;
}

  .c-timeline__track {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: var(--timeline-item-dot-outer-size);
    color: color("black");

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      width: rem(4px);
      height: rem(4px);
      background: currentColor;
      transform: translateX(-50%) rotate(45deg);
    }

    &::before {
      top: 0;
    }

    &::after {
      bottom: 0;
    }

    @include mq($from: "medium") {
      left: 50%;
      transform: translateX(-50%);
    }
  }

    .c-timeline__track-line {
      position: absolute;
      top: rem(4px);
      bottom: rem(4px);
      left: 50%;
      width: rem(1px);
      background-color: currentColor;
      transform: translateX(-50%);
    }

  .c-timeline__items {
    display: flex;
    flex-direction: column;
    padding: var(--timeline-items-y-pad) 0;
    margin: 0;
    list-style: none;

    @include mq($until: "medium") {
      --timeline-items-y-pad: #{rem(20px)};
    }

    @include mq($from: "medium") {
      --timeline-items-y-pad: #{rem(30px)};
    }
  }

    .c-timeline__item {
      --timeline-item-x-pad: #{rem(50px)};
      position: relative;

      &::before {
        content: "";
        position: absolute;
        top: var(--timeline-item-dot-top-offset);
        width: var(--timeline-item-dot-outer-size);
        height: var(--timeline-item-dot-outer-size);
        background: color("cream");
        border-radius: 50%;
      }

      &::after {
        content: "";
        position: absolute;
        top: calc(var(--timeline-item-dot-top-offset) + (var(--timeline-item-dot-outer-size) - var(--timeline-item-dot-inner-size)) * 0.5);
        width: var(--timeline-item-dot-inner-size);
        height: var(--timeline-item-dot-inner-size);
        border: rem(1px) solid color("white");
        border-radius: 50%;
      }

      & + & {
        margin-top: rem(20px);
      }

      @include mq($until: "medium") {
        padding-left: var(--timeline-item-x-pad);

        &::before {
          left: 0;
        }

        &::after {
          left: calc((var(--timeline-item-dot-outer-size) - var(--timeline-item-dot-inner-size)) * 0.5);
        }
      }

      @include mq($from: "medium") {
        width: 50%;

        &:nth-child(odd) {
          align-self: flex-end;
          padding-left: var(--timeline-item-x-pad);

          &::before {
            left: calc(var(--timeline-item-dot-outer-size) * -0.5);
          }

          &::after {
            left: calc((var(--timeline-item-dot-outer-size) - var(--timeline-item-dot-inner-size)) * 0.5 + var(--timeline-item-dot-outer-size) * -0.5);
          }
        }

        &:nth-child(even) {
          padding-right: var(--timeline-item-x-pad);
          text-align: right;

          &::before {
            right: calc(var(--timeline-item-dot-outer-size) * -0.5);
          }

          &::after {
            right: calc((var(--timeline-item-dot-outer-size) - var(--timeline-item-dot-inner-size)) * 0.5 + var(--timeline-item-dot-outer-size) * -0.5);
          }
        }
      }
    }

      .c-timeline__label {
        @include typo-h3;

        margin-top: 0;
        margin-bottom: 0;
      }

      .c-timeline__desc {
        margin-top: rem(8px);
        margin-bottom: 0;
      }
