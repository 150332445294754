/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Popin
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-popin {
  @include mq($until: "large") {
    --modal-dialog-max-width: calc(#{rem(370px)} + 2 * var(--modal-dialog-padding));
  }

  @include mq($from: "large") {
    --modal-dialog-max-width: #{rem(795px)};
  }
}

  .c-popin__backdrop {}

  .c-popin__inner {
    position: relative;
    width: 100%;

    @include mq($until: "large") {
      padding-top: rem(40px);
    }

    @include mq($from: "large") {
      display: flex;
    }
  }

    .c-popin__close {
      position: absolute;
      right: var(--modal-dialog-padding);

      @include mq($until: "large") {
        top: rem(10px);
      }

      @include mq($from: "large") {
        top: rem(20px);
      }
    }

      .c-popin__picture {
        @include mq($until: "large") {
          margin-bottom: rem(20px);
        }

        @include mq($from: "large") {
          flex: 0 0 auto;
          width: max-content;
          margin-right: rem(30px);
        }
      }

      .c-popin__infos {
        @include mq($from: "large") {
          flex: 1 1 auto;
          align-self: center;
          max-width: rem(590px);
          padding-top: rem(30px);
          padding-bottom: rem(30px);
          margin-right: auto;
          margin-left: auto;
        }

        > * {
          margin-top: 0;
          margin-bottom: 0;
        }

        > * + * {
          margin-top: rem(10px);
        }

        @include mq($from: "large") {
          text-align: center;

          .c-popin__picture + & {
            text-align: left;
          }
        }
      }

        .c-popin__alert {}

        .c-popin__title {
          @include typo-h5;
        }

        .c-popin__text {}

        .c-popin__link {
          @include mq($from: "large") {
            margin-top: rem(20px);
          }
        }
