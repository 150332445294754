/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Nav
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

@keyframes nav-mobile-opening {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}

@keyframes nav-mobile-closing {
  from { transform: translateX(0); }
  to { transform: translateX(-100%); }
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-nav {
  @include mq($until: "header-large") {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $global-z-nav;
    margin-top: var(--header-height);
    overflow: hidden;
    background: color("primary-background");

    &::after {
      @include global-k-gimmick(color("primary"));

      --gimmick-width: #{rem(582px)};

      content: "";
      position: absolute;
      top: rem(-45px);
      left: rem(-145px);
      z-index: -1;
    }

    &.is-opening {
      animation: nav-mobile-opening $animations-transition-duration ease-out;
    }

    &.is-closing {
      animation: nav-mobile-closing $animations-transition-duration ease-in;
    }

    &:not([aria-hidden="false"]):not(.is-closing) {
      display: none;
    }
  }

  @include mq($from: "header-large") {
    display: flex;
  }
}

  .c-nav__scroller {
    @include mq($until: "header-large") {
      height: 100%;
      padding: rem(30px) var(--wrapper-gutter);
      overflow-x: hidden;
      overflow-y: auto;
      overscroll-behavior: contain;
    }

    @include mq($from: "header-large") {
      display: flex;
    }
  }

    .c-nav__items {
      display: flex;
      padding: 0;
      margin: 0;
      list-style-type: none;

      @include mq($until: "header-large") {
        flex-direction: column;
        max-width: rem(600px);
        height: 100%;
        margin-right: auto;
        margin-left: auto;
      }
    }

      .c-nav__item {
        @include mq($until: "header-large") {
          &.is-hidden {
            display: none;
          }
        }

        @include mq($from: "header-large") {
          display: flex;

          + .c-nav__item {
            &::before {
              content: "";
              display: block;
              align-self: center;
              width: rem(1px);
              height: rem(24px);
              background: color("primary");
            }
          }
        }
      }

      .c-nav__item--primary {
        @include mq($until: "header-large") {
          &:not(:last-child) {
            margin-bottom: rem(40px);
          }
        }
      }

      .c-nav__item--secondary {
        @include mq($until: "header-large") {
          .c-nav__item--primary + & {
            margin-top: auto;
          }

          + .c-nav__item--secondary {
            border-top: 1px solid;
          }
        }

        @include mq($from: "header-large") {
          display: none;
        }
      }

      .c-nav__item--has-submenu {}

        .c-nav__link {
          font-weight: $FONT_EXTRABOLD;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: rem(1px);

          @include mq($until: "header-large") {
            display: flex;
            align-items: center;

            .c-nav__item--primary & {
              font-size: rem(16px);
            }

            .c-nav__item--secondary & {
              padding-top: rem(16px);
              padding-bottom: rem(16px);
              font-size: rem(14px);
            }

            .c-nav__item--has-submenu & {
              position: relative;

              &[aria-expanded] {
                padding-right: global-spacing();
                padding-left: global-spacing();
                @include transition(padding);
              }

              &[aria-expanded]::before,
              &[aria-expanded]::after {
                content: "";
                position: absolute;
                top: 50%;
                width: 1em;
                height: 1em;
                margin-top: -0.5em;
                font-size: rem(12px);
                background: no-repeat center;
                background-size: 160%;
                pointer-events: none;
                @include transition(opacity transform);
              }

              &[aria-expanded]::before {
                left: 0;
                background-image: icon(map.get($global-icons, "chevron-left"), color("foreground"));
              }

              &[aria-expanded]::after {
                right: 0;
                background-image: icon(map.get($global-icons, "chevron-right"), color("foreground"));
              }

              &[aria-expanded="false"] {
                padding-left: 0;
              }

              &[aria-expanded="false"]::before {
                opacity: 0;
                transform: translateX(#{global-spacing(-0.5)});
              }

              &[aria-expanded="true"] {
                padding-right: 0;
              }

              &[aria-expanded="true"]::after {
                opacity: 0;
                transform: translateX(#{global-spacing(0.5)});
              }
            }
          }

          @include mq($from: "header-large") {
            display: flex;
            align-items: center;
            padding-right: rem(40px);
            padding-left: rem(40px);
          }
        }

          .c-nav__link-icon {
            @include mq($until: "header-large") {
              margin-right: rem(20px);
              font-size: rem(24px);
            }
          }

          .c-nav__link-text {
            @include mq($from: "header-large") {
              position: relative;
              z-index: 1;

              &::after {
                content: "";
                position: absolute;
                right: 0;
                bottom: rem(-6px);
                left: 0;
                z-index: -1;
                height: rem(16px);
                background: color("primary");
                transform: scaleX(0);
                transform-origin: left top;
                @include transition(transform);

                .header-theme-light:not(.nav-is-open):not(.submenu-is-open):not(.search-is-open) .c-header:not(.is-floating) & {
                  background: rgba(color("white"), 0.3);
                }
              }

              .c-nav__link:hover &,
              // .c-nav__link:focus &,
              .c-nav__link[aria-expanded="true"] &,
              .c-nav__link--current {
                &::after {
                  transform: scaleX(1);
                }
              }
            }
          }

        .c-nav__submenu {
          color: color("foreground");

          &:not([aria-hidden="false"]) {
            display: none;
          }

          @include mq($until: "header-large") {
            margin-top: rem(40px);
            margin-bottom: rem(40px);
          }

          @include mq($from: "header-large") {
            position: fixed;
            top: rem(120px);
            right: 0;
            left: 0;
            overflow: hidden;
            background: color("primary-background");
            @include transition(opacity top visibility);

            &::after {
              @include global-k-gimmick(color("primary"));

              --gimmick-width: #{rem(884px)};

              content: "";
              position: absolute;
              top: rem(-105px);
              left: rem(-130px);
              z-index: -1;
            }

            .search-is-open & {
              top: calc(var(--header-height) + var(--header-search-height));
            }

            body:not(.submenu-is-open) & {
              display: block;
              visibility: hidden;
              opacity: 0;
            }

            .submenu-is-open & {
              visibility: visible;
              opacity: 1;

              &[ara-hidden="true"] {
                display: none;
              }
            }
          }
        }

          .c-nav__subscroller {
            @include mq($from: "header-large") {
              max-height: calc(100vh - #{rem(120px)});
              overflow-x: hidden;
              overflow-y: auto;
              overscroll-behavior: contain;
            }
          }

            .c-nav__subwrapper {
              @include mq($from: "header-large") {
                max-width: calc(var(--wrapper-width) + 2 * var(--wrapper-gutter));
                padding-right: var(--wrapper-gutter);
                padding-left: var(--wrapper-gutter);
                margin-right: auto;
                margin-left: auto;
              }
            }

              .c-nav__subcols {
                @include mq($from: "header-large") {
                  display: flex;
                }
              }

                .c-nav__subcols-inner {
                  @include mq($until: "header-large") {
                    + .c-nav__subcols-inner {
                      margin-top: rem(40px);
                    }
                  }

                  @include mq($from: "header-large") {
                    display: flex;
                    flex: 1 1 100%;
                    padding-top: rem(50px);
                    padding-bottom: rem(50px);

                    .c-nav__item--default & {
                      justify-content: center;
                    }

                    .c-nav__item--products & {
                      padding-top: rem(25px);
                      padding-bottom: rem(25px);
                    }

                    .c-nav__item--products &:not(.c-nav__subcols-inner--extra) {
                      display: block;
                      columns: 3;
                      column-gap: 5%;
                    }

                    .c-nav__item--products &.c-nav__subcols-inner--extra {
                      flex-direction: column;
                      margin-left: 5%;
                    }
                  }
                }

                .c-nav__subcols-inner--extra {
                  flex: 0 0 auto;
                }

                  .c-nav__subcol {
                    @include mq($until: "header-large") {
                      + .c-nav__subcol {
                        margin-top: rem(40px);
                      }
                    }

                    @include mq($from: "header-large") {
                      .c-nav__item--default & {
                        max-width: rem(280px);

                        + .c-nav__subcol {
                          margin-left: rem(24px);
                        }
                      }

                      .c-nav__item--products & {
                        break-inside: avoid;
                        padding-top: rem(25px);
                        padding-bottom: rem(25px);
                        overflow: hidden; // Needed to prevent Firefox from breaking inside
                      }

                      .c-nav__item--mag & {
                        width: 50%;

                          + .c-nav__subcol {
                            margin-top: 0;
                            margin-left: 0;
                          }

                          &:first-child {
                            padding-right: 6.66%;
                          }

                          &:last-child {
                            position: relative;
                            padding-left: 6.66%;
                          }

                          &:last-child::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            left: rem(-0.5px);
                            width: rem(1px);
                            background: currentColor;
                          }
                      }
                    }
                  }

                    .c-nav__subpicture {
                      @include mq($until: "header-large") {
                        display: none;
                      }
                    }

                    .c-nav__sublabel {
                      @include typo-h4;

                      display: flex;
                      align-items: center;
                      text-decoration: none;

                      .c-nav__subcols-inner--extra & {
                        font-family: $font-sans-serif;
                        font-size: rem(14px);
                        font-weight: $FONT_EXTRABOLD;
                        text-transform: uppercase;
                      }

                      @include mq($until: "header-large") {
                        position: relative;

                        &[aria-expanded] {
                          padding-right: global-spacing();

                          &::before {
                            content: "";
                            position: absolute;
                            top: 50%;
                            right: 0;
                            width: 1em;
                            height: 1em;
                            margin-top: -0.5em;
                            font-size: rem(12px);
                            background: icon(map.get($global-icons, "chevron-down"), color("foreground")) no-repeat center;
                            background-size: 160%;
                            @include transition(transform);
                          }
                        }

                        &[aria-expanded="true"] {
                          &::before {
                            transform: rotate(180deg);
                          }
                        }
                      }

                      @include mq($from: "header-large") {
                        .c-nav__item--default & {
                          margin-top: rem(30px);
                        }
                      }
                    }

                      .c-nav__sublabel-icon {
                        margin-right: rem(22px);
                        font-size: rem(40px);
                      }

                      .c-nav__sublabel-text {}

                    .c-nav__subcaption {
                      @include mq($until: "header-large") {
                        display: none;
                      }

                      @include mq($from: "header-large") {
                        margin-top: rem(20px);
                        margin-bottom: rem(20px);
                      }
                    }

                    .c-nav__subitems-wrapper {
                      @include mq($until: "header-large") {
                        &[aria-hidden="true"] {
                          display: none;
                        }
                      }
                    }

                      .c-nav__subitems {
                        padding: 0;
                        margin: rem(30px 0 0);
                        list-style-type: none;

                        @include mq($from: "header-large") {
                          .c-nav__item--default {
                            margin-top: rem(20px);
                          }

                          .c-nav__item--mag &:not(.c-nav__subitems--links) {
                            columns: 2;
                            column-gap: 11%;
                          }
                        }
                      }

                      .c-nav__subitems--padded {
                        padding-left: rem(62px);
                      }

                        .c-nav__subitem {
                          + .c-nav__subitem {
                            margin-top: rem(30px);

                            @include mq($from: "header-large") {
                              .c-nav__item--default & {
                                margin-top: rem(10px);
                              }
                            }
                          }
                        }

                          .c-nav__sublink {
                            text-decoration: none;
                            text-transform: uppercase;
                            letter-spacing: rem(1px);

                            @include mq($from: "header-large") {
                              position: relative;
                              z-index: 1;

                              &::after {
                                content: "";
                                position: absolute;
                                right: 0;
                                bottom: rem(-6px);
                                left: 0;
                                z-index: -1;
                                height: rem(16px);
                                background: color("white");
                                transform: scaleX(0);
                                transform-origin: left top;
                                @include transition(transform);
                              }

                              &:hover,
                              &.focus-visible {
                                &::after {
                                  transform: scaleX(1);
                                }
                              }
                            }
                          }

                    .c-nav__subfoot {
                      @include mq($until: "header-large") {
                        margin-top: rem(40px);
                      }

                      @include mq($from: "header-large") {
                        position: relative;
                        padding-top: rem(20px);
                        padding-bottom: rem(20px);
                        text-align: center;

                        &::before {
                          content: "";
                          position: absolute;
                          top: 0;
                          right: -50vw;
                          left: -50vw;
                          height: rem(1px);
                          background: color("black");
                        }
                      }
                    }
