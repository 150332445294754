/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Animate
 * Type: Utility
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 *  Styles
*******************************************************************************/

@media only screen {
  .js {
    /**
     * [1]: 99.9% value prevents Chrome to wait until the animation is launch before (lazy) loading the image.
     * [2]: Using opacity property to hide the 0.01% of picture remaining visible
    **/
    [data-animate="clipleft"],
    [data-animate="clipright"] {
      opacity: 0; // [2]
      will-change: opacity clip-path;
      @include transition(clip-path, $duration: 750ms);

      &.is-revealed {
        opacity: 1; // [2]
        clip-path: inset(0 0 0 0);
      }
    }

    [data-animate="clipleft"] {
      clip-path: inset(0 99.9% 0 0); // [1]
    }

    [data-animate="clipright"] {
      clip-path: inset(0 0 0 99.9%); // [1]
    }

    [data-animate="fade-in"] {
      opacity: 0;
      @include transition(opacity, $duration: 500ms);

      &.is-revealed {
        opacity: 1;
      }
    }

    [data-animate="gallery-main"] {
      opacity: 0;
      transform: translateX(-10%);
      @include transition(transform opacity, $duration: 2000ms);

      &.is-revealed {
        opacity: 1;
        transform: translateX(0);
      }
    }

    [data-animate="gallery-thumbs"] {
      opacity: 0;
      transform: translateY(50%);
      @include transition(transform opacity, $duration: 750ms);

      &.is-revealed {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
