/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Accordion item
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-accordion-item {}

  .c-accordion-item__title-container {
    display: block;
    cursor: pointer;
    color: color("secondary");

    &::-webkit-details-marker {
      display: none;
    }

    &::marker {
      font-size: 0;
    }

    &::before {
      content: "" !important; // Override default IE/Edge declaration
    }
  }

    .c-accordion-item__title {
      @include typo-h4;

      position: relative;
      padding-right: rem(35px);
      margin-top: 0;
      margin-bottom: 0;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        width: rem(24px);
        height: rem(24px);
        margin-top: rem(-12px);
        background: icon(map.get($global-icons, "chevron-down"), color("secondary")) no-repeat center;
        background-size: 100%;
        @include transition(transform);

        details[open] &,
        summary[aria-expanded="true"] & {
          transform: rotate(180deg);
        }
      }
    }

    .c-accordion-item__content {
      margin-top: rem(10px);
    }
