/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Label
 * Type: Component
 * Description:
 *  A label to paired with an input control to indicates what we want from the
 *  user.
**/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-label {
  @include typo-small;

  font-weight: $FONT_BOLD;

  abbr {
    text-decoration: none;
  }
}

  .c-form-label__indic {
    font-style: italic;
    font-weight: $FONT_REGULAR;
    color: #6c727f;
  }
