/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer support
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer-support {
  padding-top: rem(20px);
  padding-bottom: rem(20px);
}

  .c-footer-support__wrapper {
    --wrapper-width: #{rem(1055px)};
  }

    .c-footer-support__inner {
      border: rem(1px) solid;

      @include mq($until: "medium") {
        padding: rem(30px 26px);
      }

      @include mq($from: "medium") {
        padding: rem(20px 40px);
      }

      @include mq($from: "large") {
        display: flex;
        align-items: center;
      }
    }

      .c-footer-support__title {
        @include typo-h3;

        margin-top: 0;

        .highlight {
          color: color("secondary");
        }

        @include mq($until: "large") {
          margin-bottom: rem(20px);
          text-align: center;
        }

        @include mq($from: "large") {
          flex: 1 1 auto;
          margin-right: rem(20px);
          margin-bottom: 0;
        }
      }

      .c-footer-support__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: rem(-10px);
      }

        .c-footer-support__action {
          margin: rem(10px);
        }
