/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Tippy
 * Type: Vendor
 * Details: https://atomiks.github.io/tippyjs/
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$tooltip-arrow-size: rem(6px);
$tooltip-arrow-border-size: $tooltip-arrow-size * 0.5;
$tooltip-arrow-offset: rem(-3px);

/*******************************************************************************
 * Base
*******************************************************************************/

[data-tippy-root] {
  max-width: calc(100vw - var(--wrapper-gutter));
}

.tippy-box {
  position: relative;
  width: 100vw;
  max-width: rem(190px);
  font-size: rem(10px);
  white-space: initial;
  border-radius: rem(4px);
  outline: 0;
  transition-property: transform, visibility, opacity;

  &[data-placement^="top"] > .tippy-arrow {
    bottom: 0;

    &::before {
      bottom: $tooltip-arrow-offset;
      left: 0;
      border-width: $tooltip-arrow-border-size $tooltip-arrow-border-size 0;
      border-top-color: initial;
      transform-origin: center top;
    }
  }

  &[data-placement^="bottom"] > .tippy-arrow {
    top: 0;

    &::before {
      top: $tooltip-arrow-offset;
      left: 0;
      border-width: 0 $tooltip-arrow-border-size $tooltip-arrow-border-size;
      border-bottom-color: initial;
      transform-origin: center bottom;
    }
  }

  &[data-placement^="left"] > .tippy-arrow {
    right: 0;

    &::before {
      right: $tooltip-arrow-offset;
      border-width: $tooltip-arrow-border-size 0 $tooltip-arrow-border-size $tooltip-arrow-border-size;
      border-left-color: initial;
      transform-origin: center left;
    }
  }

  &[data-placement^="right"] > .tippy-arrow {
    left: 0;

    &::before {
      left: $tooltip-arrow-offset;
      border-width: $tooltip-arrow-border-size $tooltip-arrow-border-size $tooltip-arrow-border-size 0;
      border-right-color: initial;
      transform-origin: center right;
    }
  }

  &[data-inertia][data-state="visible"] {
    transition-timing-function: cubic-bezier(0.54, 1.5, 0.38, 1.11);
  }
}

.tippy-arrow {
  width: $tooltip-arrow-size;
  height: $tooltip-arrow-size;

  &::before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid;
  }
}

.tippy-content {
  position: relative;
  z-index: 1;
  padding: rem(6px);

  a {
    font-weight: $FONT_BOLD;
  }
}

/*******************************************************************************
 * Theme
*******************************************************************************/

.tippy-box {
  background: color("white");
  filter: drop-shadow(rem(0 4px 4px) rgba(0, 0, 0, 0.25));
}

.tippy-arrow {
  color: color("white");
}

/*******************************************************************************
 * Animation
*******************************************************************************/

.tippy-box {
  &[data-animation="fade"][data-state="hidden"] {
    opacity: 0;
  }
}
