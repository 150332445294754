/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Summary
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-summary {
  --summary-horizontal-offset: var(--wrapper-gutter);
  --summary-track-line-weight: #{rem(1px)};
  --summary-track-indic-weight: #{rem(5px)};
  --summary-track-indic-size: 0;
  --summary-track-indic-offset: 0;
  --summary-vertical-bp: #{mq-px2em(mq-get-breakpoint-width("medium"))};
}

  .c-summary__title {
    @include typo-h3;

    margin-top: 0;

    @include mq($until: "medium") {
      margin-bottom: rem(14px);
    }

    @include mq($from: "medium") {
      margin-bottom: rem(28px);
    }
  }

  .c-summary__items-container {
    position: relative;

    @include mq($until: "medium") {
      display: flex;
      margin-right: calc(var(--summary-horizontal-offset) * -1);
      margin-left: calc(var(--summary-horizontal-offset) * -1);
      overflow: auto;
    }
  }

    .c-summary__items-holder {
      position: relative;
      width: 100%;
      min-width: max-content;

      @media only print {
        min-width: 0;
      }
    }

      .c-summary__track {
        position: absolute;
        top: 0;
        color: color("secondary");

        &::before {
          content: "";
          position: absolute;
          background: currentColor;
        }

        &::after {
          content: "";
          position: absolute;
          background: currentColor;
          border-radius: rem(5px);
          @include transition(height width transform);

          // Prevent too important glitch when resizing window
          .no-transition & {
            transition: none !important;
          }
        }

        @include mq($until: "medium") {
          right: var(--summary-horizontal-offset);
          left: var(--summary-horizontal-offset);
          height: var(--summary-track-indic-weight);

          &::before {
            top: 50%;
            right: 0;
            left: 0;
            height: var(--summary-track-line-weight);
            transform: translateY(-50%);
          }

          &::after {
            top: 0;
            width: var(--summary-track-indic-size);
            height: min(100%, var(--summary-track-indic-size));
            transform: translate(var(--summary-track-indic-offset), 0);
          }
        }

        @include mq($from: "medium") {
          bottom: 0;
          left: 0;
          width: var(--summary-track-indic-weight);

          &::before {
            top: 0;
            bottom: 0;
            left: 50%;
            width: var(--summary-track-line-weight);
            transform: translateX(-50%);
          }

          &::after {
            top: 0;
            left: 0;
            width: min(100%, var(--summary-track-indic-size));
            height: var(--summary-track-indic-size);
            transform: translate(0, var(--summary-track-indic-offset));
          }
        }
      }

      .c-summary__items {
        margin: 0;
        list-style: none;

        @include mq($until: "medium") {
          display: flex;
          justify-content: space-between;
          padding: calc(#{rem(10px)} + var(--summary-track-indic-weight)) var(--summary-horizontal-offset) 0;
        }

        @include mq($from: "medium") {
          padding: 0 0 0 min(20%, #{rem(80px)});
        }

        @media only print {
          flex-wrap: wrap;
        }
      }

        .c-summary__item {
          @include transition(font-weight);

          &.is-active {
            font-weight: $FONT_BOLD;
          }

          @include mq($until: "medium") {
            max-width: rem(100px);

            & + & {
              margin-left: rem(10px);
            }
          }

          @include mq($from: "medium") {
            &:not(:first-child) {
              padding-top: rem(20px);
            }

            &:not(:last-child) {
              padding-bottom: rem(20px);
            }

            & + & {
              border-top: rem(1px) solid color("cream");
            }
          }

          @media only print {
            margin-top: rem(5px);
            margin-bottom: rem(5px);
          }
        }

        .c-summary__link {
          text-decoration: none;

          &:hover,
          &.focus-visible {
            text-decoration: underline;
          }
        }
