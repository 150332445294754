/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Field
 * Type: Component
 * Description: A group of elements which purpose is to get user's input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$form-field-control-mt: rem(4px);
$form-field-optionslist-spacing-x: rem(20px);
$form-field-optionslist-spacing-y: rem(10px);

/*******************************************************************************
 * Default styles
*******************************************************************************/

/**
 * [1] Reset fieldset styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
 */
.c-form-field {
  min-width: 0; /* [1] */
  padding: 0.01em 0 0; /* [1] */
  margin: 0; /* [1] */
  border: 0; /* [1] */

  body:not(:-moz-handler-blocked) & {
    display: table-cell; /* [1] */
  }
}

  /**
   * [1] Reset legend styles, read : https://thatemil.com/blog/2015/01/03/reset-your-fieldset/
   */
  .c-form-field__label {
    display: table; /* [1] */
    padding: 0; /* [1] */
    @include transition(color);
  }

  .c-form-field__hint {}

  .c-form-field__feedback {
    margin-bottom: rem(6px);
  }

  .c-form-field__control:not(:first-child) {
    margin-top: $form-field-control-mt;
  }

/**
 * Focus styles
*******************************************************************************/

.c-form-field:focus-within {
  .c-form-field__label {
    color: color("secondary");
  }
}

/**
 * Option list
*******************************************************************************/

.c-form-field--optionlist {
  .c-form-field__control {
    display: flex;
    flex-direction: column;
    margin: rem(-$form-field-optionslist-spacing-y * 0.5) rem(-$form-field-optionslist-spacing-x * 0.5);

    > * {
      margin: rem($form-field-optionslist-spacing-y * 0.5) rem($form-field-optionslist-spacing-x * 0.5);
    }

    &:not(:first-child) {
      margin-top: rem(-$form-field-optionslist-spacing-y * 0.5) + $form-field-control-mt;
    }
  }
}

.c-form-field--horizontal-optionlist {
  .c-form-field__control {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
