/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Skip content
 * Type: Utility
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 *  Styles
*******************************************************************************/

.u-skip-content {
  position: absolute;
  top: rem(5px);
  left: 50%;
  z-index: 1000;
  min-width: rem(240px);
  padding: 1em;
  font-weight: $FONT_BOLD;
  text-align: center;
  text-decoration: none;
  color: color("white");
  background-color: color("secondary");
  border: rem(3px) solid currentColor;
  transform: translateX(-50%) translateY(-500%);

  &:focus {
    transform: translateX(-50%) translateY(0);
  }
}
