/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Note
 * Type: Component
 * Description:
 *  An informative message indicating instructions to the user to paired with an
 *  input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$note-colors: (
  "help": #6c727f,
  "error": color("error"),
);

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-note {
  display: flex;
  align-items: center;
  @include typo-small;
}

  .c-form-note__icon {
    flex: 0 0 auto;
    margin-right: rem(4px);
    font-size: rem(18px);
  }

  .c-form-note__text {
    flex: 1 1 auto;
    padding: 0;
    margin: 0;
    list-style: none;
  }

/*******************************************************************************
 * Type modifiers
*******************************************************************************/

.c-form-note--info,
.c-form-note--success,
.c-form-note--warning,
.c-form-note--error {
  font-weight: 700;
}

@each $type, $color in $note-colors {
  .c-form-note--#{$type} { color: $color; }
}
