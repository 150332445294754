/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Inline filter
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-inline-filter {
  position: relative;
  display: inline-block;
}

  .c-inline-filter__input {
    position: absolute;
    opacity: 0;
  }

  .c-inline-filter__label {
    display: block;
    padding: rem(10px 20px);
    font-weight: $FONT_SEMIBOLD;
    line-height: 1;
    letter-spacing: em(1px, $global-font-size);
    cursor: pointer;
    border: rem(1px) solid color("black");
    border-radius: rem(40px);
    @include transition(background color);

    &:hover,
    .c-inline-filter__input.focus-visible + & {
      background: color("primary-background");
    }

    .c-inline-filter__input:checked + & {
      color: color("white");
      background: color("black");
    }
  }
