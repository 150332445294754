/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Blocks
 * Type: Object
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.o-blocks {
  @include mq($until: "medium") {
    --blocks-spacing: #{global-spacing(1.5)};
  }

  @include mq($from: "medium", $until: "large") {
    --blocks-spacing: #{global-spacing(3)};
  }

  @include mq($from: "large") {
    --blocks-spacing: #{global-spacing(5)};
  }
}

  .o-blocks__item {
    display: flow-root;

    & + & {
      margin-top: var(--blocks-spacing);
    }
  }

    .o-blocks__wrapper {}

      .o-blocks__inner {}

/*******************************************************************************
 * Variants
*******************************************************************************/

.o-blocks__item--text {}

.o-blocks__item--button,
.o-blocks__item--big-button {
  .o-blocks__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: rem(385px);
    margin-right: auto;
    margin-left: auto;

    > * + * {
      margin-top: global-spacing();
    }
  }
}

.o-blocks__item--img-text {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(790px)};
  }
}

.o-blocks__item--sep,
.o-blocks__item--form {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(590px)};
  }
}

.o-blocks__item--timeline {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(860px)};
  }
}

.o-blocks__item--newsletter {
  .o-blocks__wrapper {
    --wrapper-width: #{rem(910px)};
  }
}
