/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Home
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-home {}

/*******************************************************************************
 * Intro block
*******************************************************************************/

.c-home__intro {
  position: relative;
  overflow: hidden;

  @include mq($until: "medium") {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
    background: color("primary-background");
  }
}

  .c-home__intro-wrapper {
    @include mq($from: "medium") {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

    .c-home__intro-picture {
      @include mq($until: "medium") {
        margin-bottom: rem(10px);
      }

      @include mq($from: "medium") {
        flex: 0 0 auto;
        width: 55%;
        max-width: max-content;
        margin-top: rem(40px);
        margin-right: rem(20px);
        margin-left: calc(var(--wrapper-gutter) * -1);
      }

      @include mq($from: "large") {
        margin-top: rem(60px);
        margin-left: 0;
      }

      img {
        @include mq($from: "large") {
          max-width: min(100%, #{rem(590px)});
        }
      }
    }

    .c-home__intro-infos {
      @include mq($from: "medium") {
        position: relative;
        max-width: rem(480px);
        padding-top: rem(40px);
        padding-bottom: rem(40px);

        &::after {
          content: "";
          position: absolute;
          top: 0;
          right: calc(-100vw);
          bottom: 0;
          left: calc(-100vw);
          z-index: -1;
          background: color("primary-background");
        }
      }

      @include mq($from: "large") {
        padding-top: rem(60px);
        padding-bottom: rem(60px);
      }
    }

      .c-home__intro-title {
        @include typo-h3;

        margin-top: 0;
        margin-bottom: rem(10px);
      }

      .c-home__intro-text {
        @include mq($from: "large") {
          font-size: rem(18px);
        }
      }

/*******************************************************************************
 * Intro block
*******************************************************************************/

.c-home__highlight {
  // overflow: hidden;
  display: grid;

  &::before {
    content: "";
    align-self: stretch;
    background: var(--home-highlight-bg, #{color("primary-background")});
  }

  @include mq($until: "large") {
    grid-template-columns:
      1fr
      min(calc(100% - 2 * var(--wrapper-gutter)), var(--wrapper-width))
      1fr;

    &::before {
      grid-column: 1 / 4;
      grid-row: 2 / 4;
    }
  }

  @include mq($until: "medium") {
    grid-template-rows: auto rem(80px) auto;
  }

  @include mq($from: "medium", $until: "large") {
    grid-template-rows: auto rem(140px) auto;
  }

  @include mq($from: "large") {
    align-items: end;
    grid-template-columns:
      1fr
      min(calc(0.62 * (100% - 2 * var(--wrapper-gutter))), calc(0.62 * var(--wrapper-width)))
      min(calc(0.06 * (100% - 2 * var(--wrapper-gutter))), calc(0.06 * var(--wrapper-width)))
      min(calc(0.32 * (100% - 2 * var(--wrapper-gutter))), calc(0.32 * var(--wrapper-width)))
      1fr;
    grid-template-rows: 1fr rem(110px) auto rem(110px);

    &.c-home__highlight--end {
      grid-template-columns:
        1fr
        min(calc(0.32 * (100% - 2 * var(--wrapper-gutter))), calc(0.32 * var(--wrapper-width)))
        min(calc(0.06 * (100% - 2 * var(--wrapper-gutter))), calc(0.06 * var(--wrapper-width)))
        min(calc(0.62 * (100% - 2 * var(--wrapper-gutter))), calc(0.62 * var(--wrapper-width)))
        1fr;
    }

    &::before {
      grid-column: 1 / 6;
      grid-row: 2 / 5;
    }

  }
}

.c-home__highlight--dark {
  --home-highlight-bg: #{color("black")};
}

  .c-home__highlight-picture {
    overflow: hidden;

    @include mq($until: "large") {
      grid-column: 1 / 3;
      grid-row: 1 / 3;

      .c-home__highlight--end & {
        grid-column: 2 / 4;
      }
    }

    @include mq($from: "medium", $until: "large") {
      width: 88%;

      .c-home__highlight--end & {
        margin-left: auto;
      }
    }

    @include mq($from: "large") {
      grid-column: 1 / 3;
      grid-row: 1 / 4;

      .c-home__highlight--end & {
        grid-column: 4 / 6;
      }
    }

    img {
      @include transition(transform);
    }
  }

    .c-home__highlight-plink:hover img,
    .c-home__highlight-plink.focus-visible img {
      transform: scale(1.05);
    }

  .c-home__highlight-infos {
    .c-home__highlight--dark & {
      color: color("white");
    }

    @include mq($until: "large") {
      grid-column: 2 / 3;
      grid-row: 3 / 4;
    }

    @include mq($until: "medium") {
      padding-top: rem(20px);
      padding-bottom: rem(20px);
    }

    @include mq($from: "medium", $until: "large") {
      padding-top: rem(30px);
      padding-bottom: rem(30px);
    }

    @include mq($from: "large") {
      grid-column: 4 / 5;
      grid-row: 3 / 4;

      .c-home__highlight--end & {
        grid-column: 2 / 3;
      }
    }
  }

    .c-home__highlight-title {
      @include typo-h2;

      margin-top: 0;
      margin-bottom: 0;

      .c-home__highlight--dark & {
        color: color("primary");
      }
    }

    .c-home__highlight-caption {
      display: flex;
      flex-wrap: wrap;
      margin-top: rem(10px);
      margin-bottom: 0;
    }

    .c-home__highlight-actions {
      margin:
        calc(var(--home-highlight-actions-mt) - var(--home-highlight-actions-spacing) * 0.5)
        calc(var(--home-highlight-actions-spacing) * -0.5)
        calc(var(--home-highlight-actions-spacing) * -0.5);

      @include mq($until: "medium") {
        --home-highlight-actions-mt: #{rem(10px)};
        --home-highlight-actions-spacing: #{rem(10px)};
      }

      @include mq($from: "medium") {
        --home-highlight-actions-spacing: #{rem(20px)};
      }

      @include mq($from: "medium", $until: "large") {
        --home-highlight-actions-mt: #{rem(20px)};
      }

      @include mq($from: "large") {
        --home-highlight-actions-mt: #{rem(30px)};
      }
    }

      .c-home__highlight-action {
        margin: calc(var(--home-highlight-actions-spacing) * 0.5);
      }
