/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Product
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Structure styles
*******************************************************************************/

.c-product {}

  .c-product__breadcrumb {
    margin-top: rem(10px);

    @include mq($until: "large") {
      margin-bottom: rem(10px);
    }

    @include mq($from: "large") {
      margin-bottom: rem(20px);
    }
  }

  .c-product__head {}

  .c-product__nav,
  .c-product__blocks {
    @include mq($until: "medium") {
      margin-top: rem(30px);
    }

    @include mq($from: "medium", $until: "large") {
      margin-top: rem(40px);
    }

    @include mq($from: "large") {
      margin-top: rem(80px);
    }
  }

/*******************************************************************************
 * Essentials
*******************************************************************************/

.c-product__essentials {
  position: relative;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: -1;
  }

  &::before {
    background: color("primary-background");
  }

  &::after {
    background: color("primary");
  }

  @include mq($until: "medium") {
    padding-top: rem(20px);
    padding-bottom: rem(20px);

    &::before {
      top: 0;
      right: 0;
      bottom: 50%;
      left: 0;
    }

    &::after {
      top: 50%;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  @include mq($from: "medium", $until: "large") {
    padding-top: rem(20px);
    padding-bottom: rem(20px);

    &::before {
      top: 0;
      right: 28%;
      bottom: 50%;
      left: 0;
    }

    &::after {
      top: 50%;
      right: 0;
      bottom: 0;
      left: 28%;
    }
  }

  @include mq($from: "large") {
    &::before {
      top: 0;
      right: 45%;
      bottom: 50%;
      left: 0;
    }

    &::after {
      top: 50%;
      right: 0;
      bottom: 0;
      left: 40%;
    }
  }
}

  .c-product__essentials-wrapper {
    @include mq($from: "medium") {
      display: flex;
    }
  }

    .c-product__essentials-content {
      @include mq($from: "medium") {
        flex: 1 1 auto;
      }

      @include mq($from: "large") {
        align-items: center;
        padding-top: rem(30px);
        padding-bottom: rem(30px);
      }
    }

    .c-product__essentials-title {
      @include typo-h2;

      margin-top: 0;
      margin-bottom: 0;
    }

      .c-product__essentials-text {
        font-size: rem(14px);

        @include mq($until: "large") {
          margin-top: global-spacing();
        }

        @include mq($from: "large") {
          max-width: rem(385px);
          margin-top: global-spacing(2.5);
          margin-left: auto;
        }
      }

    .c-product__essentials-picture {
      @include mq($until: "medium") {
        margin-top: global-spacing();
      }

      @include mq($from: "medium", $until: "large") {
        flex: 0 0 auto;
        width: 48.5%;
        max-width: max-content;
        margin-right: calc(var(--wrapper-gutter) * -1);
        margin-left: 3.5%;
      }

      @include mq($from: "large") {
        width: 49%;
        margin-left: 10.5%;
      }
    }

/*******************************************************************************
 * Description
*******************************************************************************/

.c-product__desc {
  @include mq($from: "large") {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      bottom: 0;
      left: 37%;
      z-index: -1;
      background: color("primary-background");
    }
  }
}

    .c-product__desc-wrapper {
      @include mq($from: "large") {
        display: grid;
        grid-template-columns:
          1fr
          min(calc(0.405 * (100% - 2 * var(--wrapper-gutter))), calc(0.405 * var(--wrapper-width)))
          min(calc(0.595 * (100% - 2 * var(--wrapper-gutter))), calc(0.595 * var(--wrapper-width)))
          1fr;

        align-items: center;
        max-width: none;
        padding-right: 0;
        padding-left: 0;
        margin-right: auto;
        margin-left: auto;
      }
    }

      .c-product__desc-content {
        @include mq($from: "large") {
          grid-column: 3 / 4;
          grid-row: 1 / 2;
          padding-top: rem(30px);
          padding-bottom: rem(30px);
          padding-left: 17.5%;
        }
      }

        .c-product__desc-title {
          margin-top: 0;
          margin-bottom: 0;
        }

        .c-product__desc-text {
          @include mq($until: "large") {
            margin-top: global-spacing();
          }

          @include mq($from: "large") {
            margin-top: global-spacing(1.5);
          }
        }

        .c-product__desc-link {
          @include mq($until: "large") {
            margin-top: global-spacing();
          }

          @include mq($from: "large") {
            margin-top: global-spacing(1.5);
          }
        }

      .c-product__desc-picture {
        @include mq($until: "large") {
          margin-top: rem(20px);
        }

        @include mq($from: "large") {
          grid-column: 1 / 3;
          grid-row: 1 / 2;
          text-align: right;
        }
      }

/*******************************************************************************
 * Range
*******************************************************************************/

.c-product__range {
  text-align: center;
}

  .c-product__range-wrapper {
    --wrapper-width: #{rem(995px)};
  }

      .c-product__range-title {
        margin-top: 0;
        margin-bottom: 0;
      }

      .c-product__range-text {
        @include mq($until: "large") {
          margin-top: global-spacing();
        }

        @include mq($from: "large") {
          margin-top: global-spacing(1.5);
        }
      }

      .c-product__range-link {
        @include mq($until: "large") {
          margin-top: global-spacing();
        }

        @include mq($from: "large") {
          margin-top: global-spacing(1.5);
        }
      }

/*******************************************************************************
 * Caracs
*******************************************************************************/

.c-product__caracs {
  overflow: hidden;
}

  .c-product__caracs-wrapper {}

    .c-product__caracs-title {
      margin-top: 0;
      margin-bottom: 0;
    }

    .c-product__caracs-table {
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        position: relative;

        &::before {
          content: "";
          position: absolute;
          right: calc(-50vw + 50%);
          left: calc(-50vw + 50%);
          height: rem(1px);
          background: currentColor;
        }
      }

      @include mq($from: "medium", $until: "large") {
        margin-top: rem(60px);

        &::before {
          top: rem(-30px);
        }
      }

      @include mq($from: "large") {
        margin-top: rem(100px);

        &::before {
          top: rem(-50px);
        }
      }

      table {
        width: 100%;
        border-collapse: collapse;
        border: 0;

        @media only print {
          border: 1px solid !important;
        }
      }

        tr {}

          td,
          th {
            text-align: left;
            vertical-align: top;
            border: 0;

            @include mq($until: "medium") {
              width: 50%;
              padding: rem(5px 10px);
            }

            @include mq($from: "medium") {
              padding: rem(20px 40px);
            }
          }

          th {
            font-size: rem(12px);
            font-weight: $FONT_BOLD;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            background: color("primary-background");

            @include mq($from: "medium") {
              width: 34%;
              padding-left: 8.6%;
            }

            @media only print {
              border-right: 1px solid;
            }
          }

          tr:not(:first-child) th {
            border-top: rem(1px) solid color("neutral");
          }

          tr:not(:last-child) th {
            border-bottom: rem(1px) solid color("neutral");
          }

          td {
            border-top: rem(1px) solid color("primary");
            border-right: rem(1px) solid color("primary");
            border-bottom: rem(1px) solid color("primary");

            @include mq($from: "medium") {
              padding-left: 8.6%;
            }
          }
    }

/*******************************************************************************
 * Related
*******************************************************************************/

.c-product__related {
  overflow: hidden;

  @include mq($until: "medium") {
    padding-top: rem(20px);
    padding-bottom: rem(20px);
  }

  @include mq($from: "medium", $until: "large") {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
  }

  @include mq($from: "large") {
    padding-top: rem(70px);
    padding-bottom: rem(35px);
  }
}

.c-product__related--dark {
  color: color("white");
  background: color("black");
}

  .c-product__related-wrapper {}

    .c-product__related-title {
      margin-top: 0;

      @include mq($until: "large") {
        margin-bottom: rem(20px);
      }

      @include mq($from: "large") {
        margin-bottom: rem(30px);
      }
    }

    .c-product__related-slider {
      position: relative;

      .swiper {
        overflow: visible;
      }

      .swiper-wrapper {
        @media only print {
          display: grid;
          grid-template-columns: repeat(auto-fill, 200px);
          grid-gap: rem(20px);
        }
      }

      .swiper-slide {
        @media only print {
          padding: 0 !important;
          margin: 0 !important;
          border: 0 !important;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        position: absolute;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%);
      }

      .swiper-button-prev {
        @include mq($until: "medium") {
          display: none;
        }

        @include mq($from: "medium", $until: "large") {
          left: rem(20px);
        }

        @include mq($from: "large") {
          left: calc(-50vw + 50% + #{rem(55px)});
        }
      }

      .swiper-button-next {
        @include mq($until: "medium") {
          display: none;
        }

        @include mq($from: "medium", $until: "large") {
          right: rem(20px);
        }

        @include mq($from: "large") {
          right: calc(-50vw + 50% + #{rem(55px)});
        }
      }

      .swiper-scrollbar {
        --scrollbar-color: #{color("neutral")};

        @include mq($from: "medium") {
          display: none;
        }
      }
    }
