/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Header
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@keyframes slide-up {
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}

@keyframes slide-down {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}

@keyframes slide-up {
  0% { transform: translateY(0); }
  100% { transform: translateY(-100%); }
}

@keyframes slide-down {
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0); }
}

/*******************************************************************************
 * Default styles
*******************************************************************************/

:root {
  --header-height: #{rem(58px)};
  --header-large-bp: #{mq-px2em(mq-get-breakpoint-width("header-large"))};
  --header-search-height: #{rem(165px)};

  @include mq($from: "header-large") {
    --header-height: #{rem(120px)};
  }

  @media only print {
    --header-height: auto;
  }
}

.c-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $global-z-header;
  @include transition(color);

  .header-is-hidden & {
    transform: translateY(-100%);
  }

  .header-theme-light:not(.nav-is-open):not(.submenu-is-open):not(.search-is-open) &:not(.is-floating) {
    color: color("white");
  }

  &.slide-up {
    animation: slide-up $animations-transition-duration $animations-timing-function;
  }

  &.slide-down {
    animation: slide-down $animations-transition-duration $animations-timing-function;
  }

  @include mq($from: "header-large") {
    &::before {
      content: "";
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      background: rgba(color("black"), 0.5);
      opacity: 0;
      pointer-events: none;
      @include transition(opacity);
    }

    .submenu-is-open &::before {
      opacity: 1;
    }
  }

  @media only print {
    position: relative;
  }
}

.c-header--light {
  position: absolute;
  height: auto;
}

  .c-header__search {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--header-search-height);
    padding: global-spacing() var(--wrapper-gutter);
    margin-top: calc(var(--header-search-height) * -1);
    visibility: hidden;
    background: color("primary");
    @include transition(margin visibility);

    .search-is-open & {
      margin-top: 0;
      visibility: visible;
    }
  }

    .c-header__search-form {
      width: 100%;
      max-width: rem(588px);
    }

  .c-header__inner {
    position: relative;
    z-index: 2;
    height: var(--header-height);
    background: color("white");
    @include transition(background);

    .header-theme-light:not(.nav-is-open):not(.submenu-is-open):not(.search-is-open) .c-header:not(.is-floating) &,
    .header-theme-transparent:not(.nav-is-open):not(.submenu-is-open):not(.search-is-open) .c-header:not(.is-floating) & {
      background: transparent;
    }

    @include mq($until: "header-large") {
      padding: rem(12px 16px);
    }
  }

    .c-header__supnav {
      @include mq($until: "header-large") {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: $global-z-nav + 1;
      }

      @media only print {
        display: none;
      }
    }

    .c-header__main {
      @include mq($from: "header-large") {
        max-width: calc(var(--wrapper-width) + 2 * var(--wrapper-gutter));
        padding: rem(14px) var(--wrapper-gutter);
        margin-right: auto;
        margin-left: auto;
      }
    }

      .c-header__burger {
        color: currentColor;

        @include mq($until: "header-large") {
          position: absolute;
          top: 50%;
          left: rem(16px);
          z-index: $global-z-nav + 1;
          transform: translateY(-50%);
        }

        @include mq($from: "header-large") {
          display: none;
        }
      }

        .c-header__main-inner {
          display: flex;

          @include mq($until: "header-large") {
            align-items: center;
            justify-content: center;
          }

          @include mq($from: "header-large") {
            .c-header--light & {
              justify-content: center;
            }
          }
        }

          .c-header__home {
            position: relative;
            z-index: $global-z-nav + 1;
            line-height: 1;
          }

            .c-header__logo {
              --header-logo-width: #{rem(76px)};

              width: var(--header-logo-width);
              height: calc(0.41 * var(--header-logo-width));
              fill: currentColor;

              @include mq($from: "header-large") {
                --header-logo-width: #{rem(100px)};
              }
            }

          .c-header__nav {
            @include mq($from: "header-large") {
              margin-left: rem(65px);
            }
          }
