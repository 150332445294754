/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Swatch filter
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-swatch-filter {
  position: relative;
}

  .c-swatch-filter__picture {
    position: relative;
    padding-bottom: 51%;
  }

  .c-swatch-filter__picture--bordered {
    border: rem(1px) solid;
  }

  .c-swatch-filter__picture--rounded {
    width: var(--swatch-picture-rounded-size);
    height: var(--swatch-picture-rounded-size);
    padding-bottom: 0;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
    border-radius: 50%;

    @include mq($until: "medium") {
      --swatch-picture-rounded-size: #{rem(70px)};
    }

    @include mq($from: "medium") {
      --swatch-picture-rounded-size: #{rem(90px)};
    }
  }

    .c-swatch-filter__picture-inner {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .c-icon {
        font-size: rem(30px);
      }
    }

  .c-swatch-filter__infos {
    display: flex;
    align-items: center;
    margin-top: rem(10px);
  }

    .c-swatch-filter__input {
      flex: 0 0 auto;
      align-self: baseline;
      margin-top: rem(2px); // Magic number to align checkbox
    }

    .c-swatch-filter__label {
      flex: 1 1 auto;
      margin-left: rem(10px);
      font-weight: $FONT_BOLD;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .c-swatch-filter__tooltip {
      position: relative;
      flex: 0 0 auto;
      padding: 0;
      margin-left: rem(10px);
      font-size: rem(16px);
      background: transparent;
      border: 0;
    }
