/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Banner Menu
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-banner-menu {
  padding: global-spacing() 0;
  overflow: auto;
  font-weight: $FONT_BLACK;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  background: color("cream");

  @media only print {
    display: none;
  }
}

  .c-banner-menu__items {
    display: flex;
    justify-content: center;
    min-width: max-content;
    padding: 0 var(--wrapper-gutter);
    margin: 0;
    list-style: none;
  }

    .c-banner-menu__item {
      & + & {
        padding-left: global-spacing();
        margin-left: global-spacing();
        border-left: 1px solid;
      }
    }

      .c-banner-menu__link {
        text-decoration: none;

        &:hover,
        &.focus-visible {
          text-decoration: none;
        }
      }
