/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Gallery
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-gallery {
  --gallery-ratio: #{math.div(740, 1356)};
}

  .c-gallery__slider {
    .swiper-slide {
      position: relative;
      padding-bottom: calc(var(--gallery-ratio) * 100%);
      background: color("primary-background");

      @media only print {
        &:not(:first-child) {
          display: none;
        }
      }
    }

    .swiper-button-prev,
    .swiper-button-next {
      position: absolute;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);

      @include mq($until: "medium") {
        --gallery-navigation-offset: #{rem(20px)};
      }

      @include mq($from: "medium") {
        --gallery-navigation-offset: #{rem(30px)};
      }
    }

    .swiper-button-prev {
      left: var(--gallery-navigation-offset);
    }

    .swiper-button-next {
      right: var(--gallery-navigation-offset);
    }
  }

    .c-gallery__media {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      height: 100%;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }

      .c-gallery__media--video {
        --ratio-diff: calc(#{math.div(9, 16)} - var(--gallery-ratio));

        padding: calc(var(--ratio-diff) * 0%) calc(var(--ratio-diff) * 100%);
      }

  .c-gallery__thumbs-container {
    position: relative;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: rem(10px);
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, color("background") 0%, rgba(color("background"), 0) 100%);
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, color("background") 0%, rgba(color("background"), 0) 100%);
    }

    @include mq($until: "large") {
      margin-top: rem(10px);
    }

    @include mq($from: "large") {
      padding-top: rem(20px);
    }

    @media only print {
      display: none;
    }
  }

    .c-gallery__thumbs {
      overflow: auto;
    }

      .c-gallery__thumbs-items {
        display: flex;
        justify-content: center;
        min-width: min-content;
      }

        .c-gallery__thumbs-item {
          margin-left: rem(10px);

          &:last-child {
            margin-right: rem(10px);
          }
        }

          .c-gallery__thumb {
            display: flex;
            align-items: center;
            justify-content: center;
            width: var(--gallery-thumb-size);
            height: var(--gallery-thumb-size);
            padding: 0;
            background: color("cream");
            border: 0;
            opacity: 0.5;
            @include transition(opacity);

            &.is-active {
              opacity: 1;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .c-icon {
              @include mq($until: "medium") {
                font-size: rem(18px);
              }

              @include mq($from: "medium") {
                font-size: rem(24px);
              }
            }

            @include mq($until: "medium") {
              --gallery-thumb-size: #{rem(24px)};
            }

            @include mq($from: "medium", $until: "large") {
              --gallery-thumb-size: #{rem(30px)};
            }

            @include mq($from: "large") {
              --gallery-thumb-size: #{rem(60px)};
            }
          }
