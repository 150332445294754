/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Filter bar
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-filter-bar {
  --filter-bar-safe-t: #{rem(12px)};

  position: relative;
  padding-top: var(--filter-bar-safe-t); // Safe area in case one filter as a counter displayed
  margin-right: calc(var(--wrapper-gutter) * -1);
  margin-left: calc(var(--wrapper-gutter) * -1);
  overflow: auto;
  font-size: rem(12px);
  font-weight: $FONT_EXTRABOLD;
  line-height: math.div(14, 12);
  text-transform: uppercase;
}

  .c-filter-bar__items {
    display: flex;
    min-width: max-content;
    padding: 0 var(--wrapper-gutter);
    margin: 0;
    list-style: none;

    .c-filter-bar--center & {
      justify-content: center;
    }

    @include mq($until: "medium") {
      .c-filter-bar--simple-on-mobile & {
        justify-content: center;
      }
    }

    @media only print {
      flex-wrap: wrap;
      min-width: 0;
    }
  }

    .c-filter-bar__item {
      flex: 0 0 auto;

      + .c-filter-bar__item {
        margin-left: rem(10px);
      }

      @include mq($until: "medium") {
        .c-filter-bar--simple-on-mobile &:not(.c-filter-bar__item--all) {
          display: none;
        }
      }

      @media only print {
        margin-top: rem(5px);
        margin-bottom: rem(5px);
      }
    }

      .c-filter-bar__filter {
        --filter-bar-item-bg: #{color("background")};
        --filter-bar-item-color: #{color("foreground")};

        position: relative;
        display: flex;
        align-items: center;
        min-height: rem(38px);
        padding: rem(10px 20px);
        text-decoration: none;
        color: var(--filter-bar-item-color);
        background: var(--filter-bar-item-bg);
        border: rem(1px) solid color("black");
        border-radius: rem(40px);
        @include transition(background color);

        &:hover,
        &.focus-visible {
          --filter-bar-item-bg: #{color("primary-background")};
        }
      }

      .c-filter-bar__filter--all {
        --filter-bar-item-bg: #{color("black")};
        --filter-bar-item-color: #{color("white")};

        &:hover,
        &.focus-visible {
          --filter-bar-item-bg: #{rgba(color("black"), 0.8)};
          --filter-bar-item-color: #{color("white")};
        }
      }

      .c-filter-bar__filter--current {
        &,
        &:hover,
        &.focus-visible {
          --filter-bar-item-bg: #{color("black")};
          --filter-bar-item-color: #{color("white")};
        }
      }

        .c-filter-bar__filter-icon {
          margin-right: rem(10px);
          font-size: rem(16px);
        }

        .c-filter-bar__filter-count {
          position: absolute;
          top: rem(-12px);
          right: rem(-2px);
          display: flex;
          align-items: center;
          justify-content: center;
          width: rem(24px);
          height: rem(24px);
          color: color("black");
          background: color("white");
          border: rem(1px) solid;
          border-radius: 50%;
        }
