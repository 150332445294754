/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer socials
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer-socials {}

  .c-footer-socials__wrapper {
    text-align: center;
  }

    .c-footer-socials__inner {
      border: 1px solid color("secondary");

      @include mq($until: "medium") {
        padding: rem(10px);
      }

      @include mq($from: "medium") {
        display: inline-flex;
        align-items: center;
        padding: rem(12px 40px);
      }
    }

      .c-footer-socials__title {
        @include typo-h5;

        margin-top: 0;
        text-align: center;

        @include mq($until: "medium") {
          margin-bottom: rem(10px);
        }

        @include mq($from: "medium") {
          margin-right: rem(20px);
          margin-bottom: 0;
        }
      }

      .c-footer-socials__items {
        display: flex;
        padding: 0;
        font-weight: $FONT_BOLD;
        list-style: none;

        @include mq($until: "medium") {
          flex-wrap: wrap;
          margin: rem(-5px);
        }

        @include mq($from: "medium") {
          margin: rem(-10px);
        }
      }

        .c-footer-socials__item {

          @include mq($until: "medium") {
            width: 50%;
            padding: rem(5px);
            text-align: center;
          }

          @include mq($from: "medium") {
            display: flex;
            padding: rem(10px);
          }
        }

          .c-footer-socials__link {
            display: inline-flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
          }

            .c-footer-socials__icon {
              margin-right: rem(8px);
              font-size: rem(16px);
              color: color("secondary");
            }
