/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Highlight
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-highlight {
  --highlight-advertising-small-width: 82%;
  --highlight-content-spacing: #{rem(20px)};

  overflow: hidden;
  color: var(--highlight-color, currentColor);

  @include mq($from: "medium") {
    --highlight-content-spacing: #{rem(26px)};
  }
}

.c-highlight--primary,
.c-highlight--secondary {
  position: relative;
  z-index: 1;

  @include mq($until: "medium") {
    padding-top: rem(24px);
    padding-bottom: rem(24px);
  }

  @include mq($from: "medium") {
    padding-top: rem(60px);
    padding-bottom: rem(60px);
  }
}

.c-highlight--primary {
  --highlight-bg: #{color("primary")};
}

.c-highlight--secondary {
  --highlight-bg: #{color("black")};
  --highlight-color: #{color("white")};
}

.c-highlight--advertised {
  --highlight-text-align: "left";

  @include mq($until: "medium") {
    padding-top: 0;
  }

  @include mq($from: "medium") {
    padding-top: rem(94px);
  }
}

  .c-highlight__wrapper {
    .c-highlight--advertised & {
      @include mq($from: "medium") {
        display: flex;
      }
    }

    .c-highlight--end & {
      @include mq($from: "medium") {
        flex-direction: row-reverse;
      }
    }
  }

    .c-highlight__advertising {
      --highlight-ad-side-spacing: 10%;

      position: relative;
      align-self: flex-start;

      @include mq($until: "medium") {
        width: var(--highlight-advertising-small-width);
        max-width: rem(486px);
        margin-right: auto;
        margin-left: auto;
      }

      @include mq($from: "medium") {
        flex: 0 0 auto;
        width: 50%;
        max-width: max-content;
        margin-top: rem(-94px);

        .c-highlight--start & {
          margin-right: var(--highlight-ad-side-spacing);
        }

        .c-highlight--end & {
          margin-left: var(--highlight-ad-side-spacing);
        }
      }
    }

    .c-highlight__content {
      text-align: var(--highlight-text-align, center);

      .c-highlight--primary &,
      .c-highlight--secondary & {
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: -1;
          background: var(--highlight-bg);
        }
      }

      .c-highlight--gimmick & {
        &::after {
          @include global-k-gimmick(color("white"));

        --gimmick-width: #{rem(580px)};

          content: "";
          position: absolute;
          top: rem(94px + 35px);
          left: 65%;
          z-index: -1;
          opacity: 0.5;
        }
      }

      .c-highlight--advertised & {
        max-width: rem(486px);

        &::before {
          top: rem(64px);
        }

        @include mq($until: "medium") {
          margin-right: auto;
          margin-left: auto;
        }

        @include mq($from: "medium") {
          flex: 1 1 auto;
          align-self: center;
          max-width: rem(486px);
          padding-top: rem(60px);

          &::before {
            top: rem(94px);
          }
        }
      }
    }

      .c-highlight__title {
        @include typo-h2;

        margin-top: 0;
        margin-bottom: 0;

        .c-highlight--centered & {
          max-width: rem(795px);
          margin-right: auto;
          margin-left: auto;
        }

        .c-highlight--advertised & {
          @include mq($until: "medium") {
            margin-top: var(--highlight-content-spacing);
          }
        }
      }

      .c-highlight__details {
        margin-top: var(--highlight-content-spacing);

        .c-highlight--centered & {
          max-width: rem(588px);
          margin-right: auto;
          margin-left: auto;
        }
      }

      .c-highlight__button {
        margin-top: var(--highlight-content-spacing);
      }
