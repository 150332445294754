/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: FAQ
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-faq {}

  .c-faq__wrapper {
    @include mq($from: "medium") {
      display: flex;
    }
  }

    .c-faq__summary-container {
      @include mq($from: "medium") {
        flex: 0 0 auto;
        width: 30%;
        margin-right: rem(30px);
      }
    }

      .c-faq__summary {
        @include mq($from: "medium") {
          position: sticky;
          top: global-spacing();
          @include transition(top);

          .header-is-displayed & {
            top: calc(var(--header-height) + #{global-spacing()});
          }
        }
      }

    .c-faq__list {
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        flex: 1 1 auto;
      }
    }

      .c-faq__rubric {
        @include mq($until: "medium") {
          --faq-rubric-sep-spacing: #{rem(30px)};
        }

        @include mq($from: "medium") {
          --faq-rubric-sep-spacing: #{rem(40px)};
        }

        & + & {
          &::before {
            content: "";
            display: block;
            max-width: rem(360px);
            height: rem(1px);
            margin: var(--faq-rubric-sep-spacing) auto;
            background: color("secondary");
          }
        }
      }

        .c-faq__rubric-title {
          @include typo-h3;

          margin-top: 0;
          margin-bottom: rem(20px);
        }

        .c-faq__question {
          @include mq($until: "medium") {
            --faq-question-sep-spacing: #{rem(20px)};
          }

          @include mq($from: "medium") {
            --faq-question-sep-spacing: #{rem(30px)};
          }

          & + & {
            margin-top: var(--faq-question-sep-spacing);
          }
        }
