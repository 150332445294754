/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer sitemap
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer-sitemap {}

  .c-footer-sitemap__wrapper {
    @include mq($until: "large") {
      --wrapper-width: #{rem(455px)};
    }
  }

    .c-footer-sitemap__rubrics {
      padding: 0;
      margin: 0;
      list-style: none;

      @include mq($from: "large") {
        columns: 4;
        column-gap: rem(60px);
      }
    }

      .c-footer-sitemap__rubric {
        break-inside: avoid;

        + .c-footer-sitemap__rubric {
          @include mq($until: "large") {
            margin-top: rem(20px);
          }

          @include mq($from: "large") {
            margin-top: rem(30px);
          }
        }
      }

        .c-footer-sitemap__label {
          margin-top: 0;
          margin-bottom: 0;
          font-size: rem(16px);
          font-weight: $FONT_EXTRABOLD;
          text-transform: uppercase;
          letter-spacing: 0.04em;

          .c-footer-sitemap__rubric--corporate & {
            @include typo-h5;

            text-transform: none;
            letter-spacing: 0;
          }

          @include mq($until: "large") {
            position: relative;

            &[aria-expanded] {
              padding-right: global-spacing();

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                right: 0;
                width: 1em;
                height: 1em;
                margin-top: -0.5em;
                font-size: rem(12px);
                background: icon(map.get($global-icons, "chevron-down"), color("white")) no-repeat center;
                background-size: 160%;
                @include transition(transform);
              }
            }

            &[aria-expanded="true"] {
              &::before {
                transform: rotate(180deg);
              }
            }

            .c-footer-sitemap__rubric--corporate & {
              padding-right: 0;

              &::before {
                display: none;
              }
            }
          }
        }

        .c-footer-sitemap__items {
          padding: 0;
          margin: 0;
          list-style: none;

          @include mq($until: "large") {
            margin-top: rem(10px);

            &[aria-hidden="true"] {
              display: none;
            }
          }

          @include mq($from: "large") {
            margin-top: rem(16px);
          }
        }

          .c-footer-sitemap__item {
            + .c-footer-sitemap__item {
              @include mq($until: "large") {
                margin-top: rem(10px);
              }

              @include mq($from: "large") {
                margin-top: rem(16px);
              }
            }
          }

            .c-footer-sitemap__link {
              text-align: left;
            }
