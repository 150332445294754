/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Finder
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:map";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-finder {}

  .c-finder__wrapper {
    .c-finder--small & {
      --wrapper-width: #{rem(992px)};
    }
  }

    .c-finder__inner {
      overflow: hidden;
      background: color("primary-background");

      @include mq($until: "large") {
        padding: rem(30px) var(--wrapper-gutter);
        margin-right: calc(var(--wrapper-gutter) * -1);
        margin-left: calc(var(--wrapper-gutter) * -1);
      }

      @include mq($from: "large") {
        padding: rem(60px) 8%;
      }

      .c-finder--dark & {
        position: relative;
        z-index: 1;
        color: color("white");
        background: color("black");

        @include mq($from: "large") {
          &::after {
            @include global-k-gimmick(color("white"));

          --gimmick-width: #{rem(440px)};

            content: "";
            position: absolute;
            top: rem(30px);
            left: 0;
            z-index: -1;
            opacity: 0.2;
          }
        }
      }
    }

      .c-finder__title {
        margin-top: 0;
        margin-bottom: 0;
      }

      .c-finder__form,
      .c-finder__result-container {
        @include mq($until: "large") {
          margin-top: rem(20px);
        }

        @include mq($from: "large") {
          margin-top: rem(40px);
        }
      }

      .c-finder__form {}

        .c-finder__fields {
          @include mq($until: "large") {
            --grid-y-spacing: #{rem(14px)};
          }

          @include mq($from: "large") {
            --grid-y-spacing: #{rem(20px)};
          }
        }

          .c-finder__field {
            position: relative;
            color: color("foreground");
          }

            .c-finder__label {
              position: absolute;
              top: rem(16px);
              right: rem(50px);
              left: rem(20px);
              overflow: hidden;
              font-size: rem(12px);
              text-overflow: ellipsis;
              text-transform: uppercase;
              letter-spacing: em(2px, 12px);
              white-space: nowrap;
              pointer-events: none;
            }

            .c-finder__input {
              @include typo-headline($at: "small");

              display: block;
              width: 100%;
              padding: rem(45px 50px 12px 20px);
              appearance: none;
              // background: color("white");
              background: icon(map.get($global-icons, "chevron-right"), color("foreground")) right rem(20px) top 50% no-repeat color("white");
              background-size: rem(24px);
              border: 0;
              border-radius: rem(5px);
            }

      .c-finder__result-container {
        text-align: center;
      }

        .c-finder__result {
          margin-top: 0;
          margin-bottom: 0;
        }

        .c-finder__result--phrase {}

        .c-finder__result--button {}

        .c-finder__result--assoc {}

          .c-finder__result-label,
          .c-finder__result-value {
            display: inline;
            margin-right: rem(10px);
            margin-left: rem(10px);
            text-transform: uppercase;
          }

          .c-finder__result-label {
            letter-spacing: em(2px, $global-font-size);
          }

          .c-finder__result-value {
            font-weight: $FONT_EXTRABOLD;
            letter-spacing: 0.04em;
          }
