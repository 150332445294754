/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Ranges
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Structure styles
*******************************************************************************/

.c-ranges {}

  .c-ranges__section {
    & + & {
      @include mq($until: "medium") {
        margin-top: rem(30px);
      }

      @include mq($from: "medium") {
        margin-top: rem(60px);
      }
    }
  }

  .c-ranges__section--sep {
    background: color("primary-background");

    @include mq($until: "medium") {
      padding-top: rem(20px);
      padding-bottom: rem(20px);
    }

    @include mq($from: "medium") {
      padding-top: rem(40px);
      padding-bottom: rem(40px);
    }
  }

    .c-ranges__section-wrapper {}

      .c-ranges__section-title {
        @include typo-h2;

        display: flex;
        align-items: center;
        margin-top: 0;

        .c-icon {
          display: flex;
          flex: 0 0 auto;
          align-items: center;
          justify-content: center;
          width: var(--ranges-icon-outer-size);
          height: var(--ranges-icon-outer-size);
          margin-right: rem(20px);
          font-size: var(--ranges-icon-size);
          border: rem(1px) solid;
          border-radius: 50%;
        }

        @include mq($until: "medium") {
          --ranges-icon-outer-size: #{rem(45px)};
          --ranges-icon-size: #{rem(24px)};

          margin-bottom: rem(20px);
        }

        @include mq($from: "medium") {
          --ranges-icon-outer-size: #{rem(60px)};
          --ranges-icon-size: #{rem(32px)};

          margin-bottom: rem(40px);
        }
      }

  .c-ranges__chessboard,
  .c-ranges__related {
    @include mq($until: "medium") {
      margin-top: rem(30px);
    }

    @include mq($from: "medium", $until: "large") {
      margin-top: rem(60px);
    }

    @include mq($from: "medium") {
      margin-top: rem(100px);
    }
  }
