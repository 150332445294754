/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Footer legals
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-footer-legals {
  @include mq($until: "large") {
    padding-top: rem(5px);
    padding-bottom: rem(5px);
  }

  @include mq($from: "large") {
    padding-top: rem(15px);
    padding-bottom: rem(15px);
  }
}

  .c-footer-legals__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding-right: calc(var(--wrapper-gutter) - #{rem(10px)});
    padding-left: calc(var(--wrapper-gutter) - #{rem(10px)});
  }

    .c-footer-legals__group,
    .c-footer-legals__items {
      margin: rem(5px 10px);
    }

    .c-footer-legals__group {}

      .c-footer-legals__group-logo {}

    .c-footer-legals__items {
      padding: 0;
      margin-top: 0;
      margin-bottom: 0;
      text-align: center;
      list-style: none;
    }

      .c-footer-legals__item {
        display: inline;

        + .c-footer-legals__item {
          &::before {
            content: " - ";
          }
        }
      }

        .c-footer-legals__link {
          text-decoration: none;

          &:hover,
          &.focus-visible {
            text-decoration: underline;
          }
        }
