/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Heading
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-heading {
  --heading-pb: #{rem(24px)};

  position: relative;
  padding-top: rem(24px);
  padding-bottom: var(--heading-pb);
  overflow: hidden;
  background: color("primary-background");

  @include mq($from: "medium") {
    --heading-pb: #{rem(30px)};

    padding-top: rem(10px);
  }
}

.c-heading--pictured {
  color: color("white");
}

.c-heading--offset-bg {
  padding-top: calc(var(--header-height) + #{rem(24px)});
  margin-top: calc(var(--header-height) * -1);
}

.c-heading--mb {
  margin-bottom: var(--heading-mb);

  @include mq($until: "medium") {
    --heading-mb: #{rem(30px)};
  }

  @include mq($from: "medium", $until: "large") {
    --heading-mb: #{rem(60px)};
  }

  @include mq($from: "large") {
    --heading-mb: #{rem(80px)};
  }
}

  .c-heading__picture {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 56.78%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
    }

    @media only print {
      display: none;
    }
  }

  .c-heading__wrapper {
    position: relative;
    z-index: 1;

    @include mq($from: "large") {
      &::after {
        --gimmick-width: #{rem(806px)};

        content: "";
        position: absolute;
        top: rem(-879px);
        right: 0;
        z-index: -1;

        .c-heading:not(.c-heading--pictured) & {
          @include global-k-gimmick(color("cream"), $set_cp: false);
        }

        .c-heading--pictured & {
          @include global-k-gimmick(color("white"), $set_cp: false);

          opacity: 0.1;
        }
      }
    }
  }

    .c-heading__breadcrumb {
      margin-bottom: rem(10px);

      @include mq($from: "medium") {
        margin-bottom: rem(20px);
      }
    }

    .c-heading__container {
      @include mq($from: "large") {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
      }
    }

    .c-heading__content {
        @include mq($until: "medium") {
          &:not(:last-child) {
            margin-bottom: rem(10px);
          }
        }

      @include mq($from: "medium", $until: "large") {
        &:not(:last-child) {
          margin-bottom: rem(20px);
        }
      }

      @include mq($from: "large") {
        flex: 1 1 auto;
      }
    }

    .c-heading__action {
      @include mq($from: "large") {
        flex: 0 0 auto;
      }
    }

    .c-heading__category {
      @include typo-headline($at: "small");

      display: inline-block;
      padding: rem(7px 12px);
      margin-bottom: rem(10px);
      text-transform: uppercase;
      background: color("white");
    }

    .c-heading__title {
      @include typo-h1;

      max-width: rem(895px);
      margin-top: 0;
      margin-bottom: 0;
    }

    .c-heading__caption {
      max-width: rem(690px);
      margin-top: rem(10px);
      margin-bottom: 0;

      @include mq($from: "medium") {
        margin-top: rem(20px);
      }
    }

    .c-heading__date {
      @include typo-small;

      display: block;
      margin-top: rem(10px);
      color: color("secondary");
    }
