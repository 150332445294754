/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Burger
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-burger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem(24px);
  height: rem(24px);
  padding: 0;
  text-transform: uppercase;
  cursor: pointer;
  background: transparent;
  border: 0;
}

  .c-burger__icon {
    position: relative;
    display: block;
    margin: rem(7px 0);
    transition: transform ($animations-transition-duration * 0.5) cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &::before,
    &::after {
      width: rem(18px);
      height: rem(2px);
      background: currentColor;
      border-radius: rem(1.5px);
      @include transition(transform);
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
    }

    &::before {
      top: rem(-6px);
      transition: top ($animations-transition-duration * 0.5) ($animations-transition-duration * 0.8) ease, opacity ($animations-transition-duration * 0.5) ease;
    }

    &::after {
      bottom: rem(-6px);
      transition: bottom ($animations-transition-duration * 0.5) ($animations-transition-duration * 0.8) ease, transform ($animations-transition-duration * 0.5) cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  .c-burger__label {}

.c-burger[aria-expanded="true"] {
  .c-burger__icon {
    transform: rotate(45deg);
    transition-delay: $animations-transition-duration * 0.8;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

    &::before {
      top: 0;
      opacity: 0;
      transition: top ($animations-transition-duration * 0.5) ease, opacity ($animations-transition-duration * 0.5) ($animations-transition-duration * 0.8) ease;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom ($animations-transition-duration * 0.5) ease, transform ($animations-transition-duration * 0.5) ($animations-transition-duration * 0.8) cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
