/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Form's Input
 * Type: Component
 * Description: A simple text input.
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$input-select-icon: "<svg viewBox=\"0 0 18 18\" xmlns=\"http://www.w3.org/2000/svg\">\
  <path d=\"M5.33582 6.22119L8.77832 9.65619L12.2208 6.22119L13.2783 7.27869L8.77832 11.7787L4.27832 7.27869L5.33582 6.22119Z\" fill=\"%color%\" />\
</svg>";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-form-input {
  position: relative;
}

  .c-form-input__control {
    width: 100%;
    height: rem(38px);
    padding: rem(8px 12px);
    font: inherit;
    appearance: none;
    border: 1px solid #9da3ae;
    border-radius: 0;
    @include transition(background border box-shadow);

    &::placeholder {
      color: #6c727f;
    }

    &:focus {
      outline: 0;
    }

    &.focus-visible {
      border-color: transparent;
      box-shadow: rem(0 0 0 3px) color("secondary");
    }
  }

/**
 * Textarea
*******************************************************************************/

.c-form-input--textarea {
  .c-form-input__control {
    height: rem(180px);
  }
}

/**
 * Select
*******************************************************************************/

.c-form-input--select {
  .c-form-input__control {
    padding-right: rem(38px);
    background: icon($input-select-icon, color("foreground")) right rem(12px) top 50% no-repeat;
    background-size: rem(18px 18px);
  }
}

/**
 * File
*******************************************************************************/

.c-form-input__control[type="file"] {
  &::-webkit-file-upload-button {
    line-height: 14px; // Magic number to present it as centered as we can
  }
}

/*******************************************************************************
 * With action
*******************************************************************************/

@keyframes input-action-loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
}

.c-form-input--waction {
  .c-form-input__control {
    padding-right: rem(12px * 2 + 18px);
  }
}

  .c-form-input__action {
    position: absolute;
    top: rem(8px);
    right: rem(12px);
    bottom: rem(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: rem(20px);
    height: rem(20px);
    padding: 0;
    font-size: rem(20px);
    background: transparent;
    border: 0;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: rem(2px) solid currentColor;
      border-radius: 50%;
      opacity: 0;
      @include transition(opacity);
    }

    &::after {
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }

    .c-icon {
      @include transition(opacity);
    }

    &.is-loading {
      &::before {
        opacity: 0.2;
      }

      &::after {
        opacity: 1;
        animation: input-action-loading 750ms linear infinite;
      }

      .c-icon {
        opacity: 0;
      }
    }
  }

/*******************************************************************************
 * Feedback styles
*******************************************************************************/

.c-form-input--error {
  .c-form-input__control {
    border-color: transparent;

    &:not(.focus-visible) {
      background: color("error-100");
      box-shadow: rem(0 0 0 3px) color("error");
    }
  }
}
