/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Spacings
 * Type: Utility
 * Description: Inspired from https://css-tricks.com/efficient-infinite-utility-helpers-using-inline-css-custom-properties-and-calc/
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$spacings-unit: $global-spacing-unit;
$spacings-properties: (
  "m": "margin",
  // "p": "padding",
);
$spacings-directions: (
  "t": "-top",
  "r": "-right",
  "b": "-bottom",
  "l": "-left",
  "x": (
    "-right",
    "-left",
  ),
  "y": (
    "-top",
    "-bottom",
  ),
  "": null,
);
$spacings-breakpoints: $global-core-breakpoints;
$spacing-breakpoint-separator: "_";

/*******************************************************************************
 * Logic
*******************************************************************************/

@mixin spacings-ruleset($property, $direction, $custom-property) {
  [style*="--#{$custom-property}:"] {
    @each $dir in $direction {
      #{$property}#{$dir}: calc(var(--#{$custom-property}) * #{rem($spacings-unit)}) !important;
    }
  }
}

@mixin spacings-generator($breakpoint: null) {
  @each $property-key, $property in $spacings-properties {
    @each $direction-key, $direction in $spacings-directions {
      $custom-property: "#{$property-key}#{$direction-key}";

      @if ($breakpoint) {
        $custom-property: "#{$custom-property}#{$spacing-breakpoint-separator}#{$breakpoint}";

        @include spacings-ruleset($property, $direction, $custom-property);
      } @else {
        @include spacings-ruleset($property, $direction, $custom-property);
      }
    }
  }
}

/*******************************************************************************
 * Output
*******************************************************************************/

@include spacings-generator;

@each $breakpoint-name, $breakpoint-width in $spacings-breakpoints {
  @include mq($from: $breakpoint-name) {
    @include spacings-generator($breakpoint-name);
  }
}
