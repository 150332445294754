/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Slideshow
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-slideshow {
  margin-top: calc(var(--header-height) * -1);

  .swiper-button-prev,
  .swiper-button-next {
    @include mq($until: "medium") {
      display: none;
    }

    @include mq($from: "medium") {
      position: absolute;
      top: calc(0.5 * (100% - var(--header-height)) + var(--header-height));
      z-index: 1;
      transform: translateY(-50%);
    }
  }

  .swiper-button-prev {
    @include mq($from: "medium", $until: "large") {
      left: rem(30px);
    }

    @include mq($from: "large") {
      left: rem(50px);
    }
  }

  .swiper-button-next {
    @include mq($from: "medium", $until: "large") {
      right: rem(30px);
    }

    @include mq($from: "large") {
      right: rem(50px);
    }
  }

  .swiper-pagination {
    position: absolute;
    right: var(--wrapper-gutter);
    bottom: rem(30px);
    left: var(--wrapper-gutter);
    z-index: 1;
  }
}

  .c-slideshow__slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--header-height);

    @include mq($until: "large") {
      height: rem(600px);
    }

    @include mq($from: "large") {
      height: rem(900px);
    }

    @media only print {
      height: auto;
    }
  }

    .c-slideshow__slide-picture {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 56.78%), linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
      }

      &::after {
        @include global-k-gimmick(color("white"));

        content: "";
        position: absolute;
        opacity: 0.2;
        pointer-events: none;

        @include mq($until: "medium") {
          --gimmick-width: #{rem(450px)};

          top: rem(5px);
          left: rem(-10px);
        }

        @include mq($from: "medium", $until: "large") {
          --gimmick-width: #{rem(460px)};

          top: rem(5px);
          left: rem(-10px);
        }

        @include mq($from: "large") {
          --gimmick-width: #{rem(1010px)};

          top: rem(-100px);
          left: rem(-20px);
        }
      }

      @media only print {
        display: none;
      }
    }

    .c-slideshow__slide-infos {
      position: relative;
      z-index: 2;
      max-width: calc(#{rem(585px)} + 2 * var(--wrapper-gutter));
      padding: var(--wrapper-gutter);
      text-align: center;
      color: color("white");

      @include mq($from: "medium") {
        width: calc(60% + 2 * var(--wrapper-gutter));
      }

      > * {
        margin-top: 0;
        margin-bottom: 0;
      }

      > * + * {
        @include mq($until: "large") {
          margin-top: rem(10px);
        }

        @include mq($from: "large") {
          margin-top: rem(20px);
        }
      }
    }

      .c-slideshow__slide-title,
      .c-slideshow__slide-subtitle {
        display: block;
      }

      .c-slideshow__slide-title {
        @include typo-h1;
      }

      .c-slideshow__slide-subtitle {
        @include typo-headline;
      }

      .c-slideshow__slide-desc {}

      .c-slideshow__slide-button {}
