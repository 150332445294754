/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Catalog card
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@use "sass:math";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-catalog-card {
  position: relative;
}

  .c-catalog-card__picture {
    overflow: hidden;
    @include transition(box-shadow);

    img {
      @include transition(transform);
    }

    .c-catalog-card--interactive:hover &,
    .c-catalog-card--interactive:focus-within & {
      box-shadow: rem(0 10px 10px) rgba(color("black"), 0.1);

      img {
        transform: scale(1.05);
      }
    }
  }

  .c-catalog-card__title {
    margin: rem(10px 0 0);
    font-family: $font-sans-serif;
    font-size: rem(16px);
    font-weight: $FONT_BOLD;
    line-height: math.div(18.78, 16);

    .c-catalog-card--range & {
      line-height: math.div(26, 18);

      @include mq($until: "medium") {
        font-size: rem(12px);
      }

      @include mq($from: "medium") {
        font-size: rem(18px);
      }
    }
  }

    .c-catalog-card__link {
      text-decoration: none;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

  .c-catalog-card__caption {
    margin: rem(5px 0 0);
  }
