/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Google Places AutoComplete
 * Type: Vendor
 * Details: https://developers.google.com/maps/documentation/javascript/places-autocomplete#style-autocomplete
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

$pac-pin-icon: "<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 24 24\">\
  <path d=\"M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z\" fill=\"%color%\"/>\
</svg>";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.pac-container {
  border: 0;
  box-shadow: rem(0 6px 20px) rgba(0, 0, 0, 0.15);
  transform: translateY(#{rem(6px)});

  &::after {
    display: none;
  }
}

.pac-item {
  display: flex;
  align-items: center;
  padding: rem(12px);
  border: 0;
}

.pac-icon,
.hdpi .pac-icon {
  flex: 0 0 auto;
  width: rem(24px);
  height: rem(24px);
  margin: rem(0 6px 0 0);
  background: icon($pac-pin-icon, color("neutral")) center center no-repeat;
  background-size: rem(24px 24px);
}

.pac-item:hover,
.pac-item-selected {
  background: color("primary-background");

  .pac-icon,
  .hdpi & .pac-icon {
    color: color("neutral");
    background-image: icon($pac-pin-icon, color("black"));
  }
}

.pac-item-query {
  @include typo-small;

  + span {
    @include typo-very-small;

    opacity: 0.5;
  }
}

.pac-matched {}
