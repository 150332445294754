/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Drive to store
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-drivetostore {}

  .c-drivetostore__heading {
    @include mq($from: "medium") {
      padding-bottom: calc(#{rem(135px)} + var(--heading-pb));
    }
  }

  .c-drivetostore__form {
    position: relative;
    z-index: 1;
    background: color("white");
    box-shadow: rem(0 20px 40px) rgba(0, 0, 0, 0.25);

    @include mq($until: "medium") {
      padding: rem(20px) var(--wrapper-gutter);
      margin-right: calc(var(--wrapper-gutter) * -1);
      margin-left: calc(var(--wrapper-gutter) * -1);
    }

    @include mq($from: "medium") {
      max-width: rem(990px);
      padding: rem(40px);
      margin-top: rem(-135px);
      margin-right: auto;
      margin-left: auto;
    }
  }

    .c-drivetostore__form-inner {
      max-width: rem(590px);
      margin-right: auto;
      margin-left: auto;
    }

      .c-drivetostore__form-fields {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        margin: rem(-10px);
      }

        .c-drivetostore__form-field {
          margin: rem(10px);
        }

        .c-drivetostore__form-field--search {
          width: 100%;
        }

        .c-drivetostore__form-field--submit {
          @include mq($until: "medium") {
            width: 100%;
            text-align: center;
          }
        }

  .c-drivetostore__locator {
    --wrapper-width: #{rem(1600px)};

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(50px);
    }
  }

    .c-drivetostore__count {
      text-align: center;
    }

    .c-drivetostore__results {
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        margin-top: rem(50px);
      }

      @include mq($from: "large") {
        display: flex;
        flex-direction: row-reverse;
        height: rem(1000px);
        max-height: calc(100vh - var(--header-height));
      }
    }

      .c-drivetostore__list {
        padding-bottom: rem(60px);
        padding-left: var(--wrapper-gutter);
        margin-left: calc(var(--wrapper-gutter) * -1);

        @include mq($until: "large") {
          max-height: calc(100vh - var(--header-height));
          padding-top: rem(20px);
          padding-right: var(--wrapper-gutter);
          margin-right: calc(var(--wrapper-gutter) * -1);
          overflow: auto;
        }

        @include mq($from: "large") {
          flex: 0 0 auto;
          width: calc(#{rem(385px + 30px)} + var(--wrapper-gutter));
          padding-top: rem(20px);
          padding-right: rem(30px);
          margin-top: rem(-20px);
          overflow: auto;
        }
      }

        .c-drivetostore__item {}

        .c-drivetostore__item + .c-drivetostore__item {
          margin-top: rem(20px);
        }

      .c-drivetostore__map-container {
        @include mq($until: "large") {
          height: rem(665px);
          max-height: calc(100vh - var(--header-height));
        }

        @include mq($from: "large") {
          flex: 1 1 auto;
        }

        @media only print {
          display: none;
        }
      }

        .c-drivetostore__map {
          position: relative;
          width: 100%;
          height: 100%;
          background: color("neutral");

          .gm-style {
            font: inherit;
          }

          [class*="cluster-"] {
            @include typo-small;

            display: flex;
            align-items: center;
            justify-content: center;
            font-family: inherit;
            font-weight: $FONT_BOLD;
            background: color("white");
            border-radius: 50%;
            box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);

            &::before {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              border: rem(1px) solid;
              border-radius: 50%;
              transform: scale(70%);
            }
          }
        }

          .c-drivetostore__locator-iw {
            position: absolute;
            width: 90%;
            max-width: rem(385px);
            margin-top: rem(-10px);
          }

          .c-drivetostore__switch-container {
            position: sticky;
            bottom: rem(20px);
            text-align: center;

            @media only print {
              display: none;
            }
          }

            .c-drivetostore__switch {
              display: inline-flex;
              margin-top: rem(20px);
              overflow: hidden;
              font-size: rem(12px);
              font-weight: $FONT_BOLD;
              line-height: 1;
              background: color("white");
              border-radius: rem(5px);
              box-shadow: rem(0 4px 4px) rgba(0, 0, 0, 0.25);

              @include mq($from: "large") {
                display: none;
              }
            }

              .c-drivetostore__anchor {
                display: flex;
                align-items: center;
                padding: rem(12px 10px);
                text-decoration: none;
                text-transform: uppercase;
                border-radius: rem(5px);

                &.is-active {
                  color: color("white");
                  background: color("black");
                }
              }

                .c-drivetostore__anchor-icon {
                  margin-right: rem(8px);
                  font-size: rem(16px);
                  color: color("secondary");
                }

  .c-drivetostore__antennas {
    @include mq($until: "medium") {
      margin-top: rem(50px);
    }

    @include mq($from: "medium") {
      margin-top: rem(100px);
    }
  }

  .c-drivetostore__text {
    max-width: rem(895px);

    @include mq($until: "medium") {
      margin-top: rem(20px);
    }

    @include mq($from: "medium") {
      margin-top: rem(50px);
    }
  }
