/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Swiper
 * Type: Vendor
 * Details: https://swiperjs.com/
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

@import "swiper/swiper";
@import "swiper/modules/a11y/a11y";

/*******************************************************************************
 * Default styles
*******************************************************************************/

.swiper {}

  .swiper-wrapper {
    @media only print {
      flex-direction: column;
      transform: none !important;
    }
  }

/**
 * Slide
*******************************************************************************/

.swiper-slide {
  box-sizing: border-box;

  @media only print {
    break-inside: avoid;
    width: 100% !important;

   &:not(.swiper-slide-duplicate) + .swiper-slide {
      border-top: 1px dashed;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

.swiper-slide-duplicate {
  @media only print {
    display: none;
  }
}

/**
 * Navigation
*******************************************************************************/

.swiper-button-prev,
.swiper-button-next {
  --swiper-navigation-size: #{rem(60px)};
  --swiper-navigation-icon-size: #{rem(20px)};

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--swiper-navigation-size);
  height: var(--swiper-navigation-size);
  padding: 0;
  font-size: var(--swiper-navigation-icon-size);
  background: color("white");
  border: 0;
  border-radius: 50%;
  box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);

  .c-icon {
    position: relative;
  }

  @include mq($until: "medium") {
    --swiper-navigation-size: #{rem(20px)};
    --swiper-navigation-icon-size: #{rem(10px)};
  }

  @include mq($from: "medium") {
    &::before {
      content: "";
      position: absolute;
      top: 15%;
      right: 15%;
      bottom: 15%;
      left: 15%;
      background: color("white");
      border: rem(1px) solid transparent;
      border-radius: 50%;
      box-shadow: rem(0 20px 40px) rgba(19, 29, 70, 0.1);
      @include transition(border);
    }

    &:hover,
    &.focus-visible {
      &::before {
        border-color: color("black");
      }
    }
  }

  @include mq($from: "medium", $until: "large") {
    --swiper-navigation-size: #{rem(40px)};
  }

  @media only print {
    display: none;
  }
}

.swiper-button-disabled {
  display: none;
}

/**
 * Scrollbar
*******************************************************************************/

.swiper-scrollbar {
  position: relative;
  height: rem(5px);
  margin-top: rem(20px);

  &::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: rem(1px);
    background: var(--scrollbar-color, currentColor);
    transform: translateY(-50%);
  }

  @media only print {
    display: none;
  }
}

  .swiper-scrollbar-drag {
    position: absolute;
    top: 0;
    bottom: 0;
    background: var(--scrollbar-color, currentColor);
    border-radius: rem(5px);
  }

/**
 * Pagination
*******************************************************************************/

.swiper-pagination {
  --swiper-pagination-bullet-spacing: #{rem(3px)};

  display: flex;
  justify-content: center;

  @media only print {
    display: none;
  }
}

.swiper-pagination-bullet {
  --swiper-pagination-bullet-bg: #{color("white")};

  width: rem(12px);
  height: rem(12px);
  margin: var(--swiper-pagination-bullet-spacing);
  background: var(--swiper-pagination-bullet-bg);
  border-radius: 50%;
  transform: scale(0.5);
  @include transition(background transform);
}

.swiper-pagination-bullet-active {
  transform: scale(1);
}

// // Pagination
// .swiper-pagination-bullet {
//   border: rem(2px) solid;
//   border-radius: 50%;
//   cursor: pointer;
//   display: inline-block;
//   position: relative;
//   height: rem(16px);
//   width: rem(16px);

//   &::before {
//     content: "";
//     background: currentColor;
//     bottom: rem(-1px);
//     border-radius: 50%;
//     left: rem(-1px);
//     opacity: 0;
//     position: absolute;
//     right: rem(-1px);
//     top: rem(-1px);
//     @include transition(opacity);
//   }

//   &:hover,
//   &:focus {
//     &::before { opacity: 0.5; }
//   }

//   &.swiper-pagination-bullet-active {
//     &::before { opacity: 1; }
//   }
// }


// /**
//  * Print
//  **********************************************************************************/

// @media only print {
//   .swiper-wrapper {
//     flex-wrap: wrap;
//     transform: none !important;
//   }

//     .swiper-slide {
//       margin: 0;
//       width: 100% !important;

//       &.swiper-slide-duplicate { display: none; }

//       &:not(.swiper-slide-duplicate) + .swiper-slide {
//       //   border-top: 1px dashed;
//         margin-top: rem(10px);
//         padding-top: rem(10px);
//       }
//     }

//   .swiper-buttons { display: none; }
// }
