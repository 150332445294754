/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: Sup Nav
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-supnav {
  @include mq($from: "header-large") {
    border-bottom: rem(0.5px) solid;
  }
}

  .c-supnav__items {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;

    @include mq($until: "header-large") {
      height: 100%;
    }

    @include mq($from: "header-large") {
      justify-content: flex-end;
      max-width: calc(var(--wrapper-width) + 2 * var(--wrapper-gutter));
      padding-right: var(--wrapper-gutter);
      padding-left: var(--wrapper-gutter);
      margin-right: auto;
      margin-left: auto;
    }
  }

    .c-supnav__item {}

    .c-supnav__item--showroom,
    .c-supnav__item--catalog {
      @include mq($until: "header-large") {
        display: none;
      }
    }

      .c-supnav__link {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        padding: rem(12px 16px);
        text-decoration: none;
        letter-spacing: rem(1px);
        @include transition(background);

        &:hover,
        &.focus-visible {
          color: color("black");
          background: color("primary-background");
        }

        .c-supnav__item--catalog & {
          font-weight: $FONT_BOLD;
          color: color("white");
          background: color("black");

          &:hover,
          &.focus-visible {
            background: rgba(color("black"), 0.8);
          }

          .header-theme-light:not(.nav-is-open):not(.submenu-is-open):not(.search-is-open) .c-header:not(.is-floating) & {
            color: color("black");
            background: color("white");

            &:hover,
            &.focus-visible {
              background: rgba(color("white"), 0.8);
            }
          }
        }
      }

      .c-supnav__search-toggler {
        .search-is-open & {
          color: color("black");
          background: color("cream");
        }
      }

        .c-supnav__link-icon {
          font-size: rem(24px);
        }

        .c-supnav__link-text {
          @include mq($until: "header-large") {
            @include hidden-visually;
          }

          @include mq($from: "header-large") {
            margin-left: rem(10px);

            .c-supnav__search-toggler & {
              @include hidden-visually;
            }
          }
        }
