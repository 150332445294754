/*******************************************************************************
 * Infos
*******************************************************************************/

/**
 * Name: News push
 * Type: Component
**/

/*******************************************************************************
 * Utils
*******************************************************************************/

/*******************************************************************************
 * Default styles
*******************************************************************************/

.c-news-push {
  overflow: hidden;
  background: color("primary-background");

  @include mq($until: "large") {
    padding-top: rem(30px);
    padding-bottom: rem(30px);
  }

  @include mq($from: "large") {
    padding-top: rem(40px);
    padding-bottom: rem(40px);
  }
}

  .c-news-push__wrapper {
    position: relative;
    z-index: 1;

    &::after {
      @include global-k-gimmick(color("primary"));

    --gimmick-width: #{rem(580px)};

      content: "";
      position: absolute;
      top: rem(140px);
      right: rem(-60px);
      z-index: -1;

      @include mq($from: "medium") {
        top: rem(10px);
      }
    }
  }

    .c-news-push__title {
      @include typo-h4;

      margin-top: 0;
      margin-bottom: 0;
    }

    .c-news-push__grid {
      @include mq($until: "medium") {
        margin-top: rem(20px);
      }

      @include mq($from: "medium") {
        margin-top: rem(30px);
      }
    }

    .c-news-push__foot {
      margin-top: rem(20px);
      text-align: center;
    }
